import * as THREE from "three";

class U {
	static Clamp(val, min, max, offset = 0) {
		return val > max + offset ? max : val < min - offset ? min : val;
	}

	static FormatNumber(num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	static MapRange(value, aMin, aMax, bMin, bMax) {
		return bMin + ((bMax - bMin) * (value - aMin)) / (aMax - aMin);
	}

	static GetRandomIntRange(min, max) {
		min = Math.ceil(min);
		max = Math.ceil(max);
		return Math.floor(Math.random() * (max - min) + min);
	}

	static GetInterpolatedVector(origin, target, value) {
		const result = {
			x: U.MapRange(value, 0, 1, origin.x, target.x),
			y: U.MapRange(value, 0, 1, origin.y, target.y),
			z: U.MapRange(value, 0, 1, origin.z, target.z),
		};

		return new THREE.Vector3(result.x, result.y, result.z);
	}

	static RoundNum(number, precision) {
		var shift = function (number, precision, reverseShift) {
			if (reverseShift) {
				precision = -precision;
			}
			var numArray = ("" + number).split("e");
			return +(numArray[0] + "e" + (numArray[1] ? +numArray[1] + precision : precision));
		};
		return shift(Math.round(shift(number, precision, false)), precision, true);
	}

	static calibrateRate() {
		const refPointA = { lat: 51.420443, lon: -0.991577 };
		const refPointB = { lat: 51.423438, lon: -0.987596 };

		// 		// target
		const pointA = { x: -299.605, z: 44.67 };
		const pointB = { x: -23.372, z: -286.784 };

		// Xlon, Zlat

		const diffInLat = pointA.z - pointB.z;
		const diffInLon = pointB.x - pointA.x;

		return {
			latRate: (refPointA.lat - refPointB.lat) / diffInLat,
			lonRate: (refPointB.lon - refPointA.lon) / diffInLon,
		};
	}

	static getRealWorldPosition(_vector = { x: 91.498, z: 254.232 }) {
		const originLatitude = 51.420919;
		const originLongitude = -0.987335;

		const calib = U.calibrateRate();

		const changeInLongitude = calib.lonRate * _vector.x;
		const realWorldLongitude = originLongitude + changeInLongitude;

		const changeInLatitude = calib.latRate * _vector.z;
		const realWorldLatitude = originLatitude + changeInLatitude;

		return { lat: realWorldLatitude, lon: realWorldLongitude };
	}

	static getPosByLatLon(realWorldLatitude, realWorldLongitude, y = 6) {
		const originLatitude = 51.420919;
		const originLongitude = -0.987335;

		const calib = U.calibrateRate();

		const differenceInLatitude = realWorldLatitude - originLatitude;
		const differenceInLongitude = realWorldLongitude - originLongitude;

		const x = differenceInLongitude / calib.lonRate;
		const z = differenceInLatitude / calib.latRate;

		return new THREE.Vector3(x, y, z);
	}
	static convertDegreesToRadians(degrees) {
		return degrees * (Math.PI / 180);
	}
	static convertRadiansToDegrees(radians) {
		return radians / (Math.PI / 180);
	}
	static computeGroupCenter(group, optionalTarget = new THREE.Vector3()) {
		const childBox = new THREE.Box3();
		const groupBox = new THREE.Box3();
		const invMatrixWorld = new THREE.Matrix4();
		group.traverse((child) => {
			if (child instanceof THREE.Mesh) {
				if (!child.geometry.boundingBox) {
					child.geometry.computeBoundingBox();
					childBox.copy(child.geometry.boundingBox);
					child.updateMatrixWorld(true);
					childBox.applyMatrix4(child.matrixWorld);
					groupBox.min.min(childBox.min);
					groupBox.max.max(childBox.max);
				}
			}
		});
		// All computations are in world space
		// But the group might not be in world space
		group.matrixWorld.getInverse(invMatrixWorld);
		groupBox.applyMatrix4(invMatrixWorld);
		groupBox.getCenter(optionalTarget);
		return optionalTarget;
	}

	static GetResponsiveMode() {
		let mode = "desktop";
		let modeIndex = 0;
		const width = window.innerWidth;
		const height = window.innerHeight;
		const isTouch = window.ontouchstart !== undefined;

		if (width <= 1100 && width > 568) {
			mode = "tablet";
			modeIndex = 1;
		} else if (width <= 568) {
			mode = "mobile";
			modeIndex = 2;
		} else {
			mode = "desktop";
			modeIndex = 0;
		}

		const rotation = height > width ? "portrait" : "landscape";

		return {
			mode: mode,
			modeIndex: modeIndex,
			orientation: rotation,
			highDefMode: modeIndex >= 1 || isTouch ? true : false,
			isTouch: isTouch,
		};
	}

	static detectIE() {
		let ua = window.navigator.userAgent;
		let msie = ua.indexOf("MSIE ");
		if (msie > 0) {
			// IE 10 or older => return version number
			return true;
		}

		let trident = ua.indexOf("Trident/");
		if (trident > 0) {
			// IE 11 => return version number

			return true;
		}

		// let edge = ua.indexOf('Edge/');
		// if (edge > 0) {
		// 	// Edge (IE 12+) => return version number

		// 	return true;
		// }

		// other browser
		return false;
	}

	static DetectCollision(object1, object2) {
		if (object1.uuid === undefined || object2.uuid === undefined) {
			console.error("DETECT COLLISION: INVALID OBJECT GIVEN");
			return;
		}

		object1.geometry.computeBoundingBox();
		object2.geometry.computeBoundingBox();
		object1.updateMatrixWorld();
		object2.updateMatrixWorld();

		const box1 = object1.geometry.boundingBox.clone();
		box1.applyMatrix4(object1.matrixWorld);

		const box2 = object2.geometry.boundingBox.clone();
		box2.applyMatrix4(object2.matrixWorld);

		return box1.intersectsBox(box2);
	}

	static SetProportionalSize(width, height, max, mode = "auto") {
		/* This function will return dimensions with the largest set to maximum allowed value, while the rest adjusted to keep original ratio.
		Perfect for resizing images while keeping their original ratio  */

		const ratio = width >= height ? height / width : width / height;

		if (mode === "auto") {
			//AUTO will set the largest to max
			if (width > height) {
				return { width: max, height: ratio * max, ratio: ratio };
			} else if (height > width) {
				return { width: ratio * max, height: max, ratio: ratio };
			} else {
				return { width: max, height: max, ratio: 1 };
			}
		} else if (mode === "height") {
			//HEIGHT will force set max to height only
			const dec = max / height;
			return { width: width * dec, height: max, ratio: ratio };
		} else if (mode === "width") {
			//WIDTH will force set max to width
			const dec = max / width;
			return { width: max, height: height * dec, ratio: ratio };
		}
	}
}

export default U;
