import React, { Component } from "react";
import * as THREE from "three";
import U from "../utils";
import { loaderTextData } from "../data/loaderTextData";

class Loader extends Component {
	constructor() {
		super();

		this.SetProgress = this.SetProgress.bind(this);
		this.OutroAnim = this.OutroAnim.bind(this);
		this.loadTimeout = null;
		this.loaderTextTimeout = null;
		this.isLoadingText = true;

		THREE.DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
			this.SetProgress((itemsLoaded / itemsTotal) * 100);
		};

		this.loadTimeout = this.LoadTimeout();

		THREE.DefaultLoadingManager.onLoad = () => {
			setTimeout(() => {
				this.OutroAnim();
				clearInterval(this.loadTimeout);
				this.FireEvent();
			}, 1000);
		};
	}

	componentDidMount() {
		setTimeout(() => {
			this.IntroAnim();
		}, 200);
	}

	LoadTimeout() {
		//THIS RELOADS THE PAGE IF IT TAKES TOO LONG TO LOAD
		//DEFAULT IS 30 SECS
		return setTimeout(() => {
			console.log("FORCE RELOAD!");
			window.location.reload();
		}, 20000);
	}

	SetProgress(percentage) {
		this.loaderLine.style.width = `${U.Clamp(percentage, 0, 100)}%`;
		// this.progressDiv.style.width = `${U.Clamp(percentage, 0, 100)}%`;
		// this.loadingPercent.innerHTML = `${U.RoundNum(percentage, 0)}%`;
	}

	FireEvent() {
		console.log("LOAD DONE!");
		const eventDelegate = new CustomEvent("loadDone", {
			bubbles: true,
			detail: "LoadComplete",
		});
		document.dispatchEvent(eventDelegate);
	}

	IntroAnim() {
		this.loadingDiv.classList.remove("fade");

		this.NextLoaderText();
	}

	NextLoaderText() {
		if (!this.isLoadingText) return;
		this.loadingText.classList.add("fade");
		setTimeout(() => {
			this.loadingText.innerHTML =
				loaderTextData[U.GetRandomIntRange(0, loaderTextData.length - 1)];
			this.loadingText.classList.remove("fade");

			this.loaderTextTimeout = setTimeout(() => {
				if (!this.isLoadingText) return;
				this.NextLoaderText();
			}, 2400);
		}, 200);
	}

	OutroAnim() {
		const iconsArray = [...document.querySelectorAll(".intro-icon")];
		setTimeout(() => {
			this.loaderLine.classList.add("loader-bar-outro");
			this.introCont.classList.add("fade");
			this.loadingDiv.classList.add("fade");
			clearInterval(this.loaderTextTimeout);
			this.isLoadingText = false;

			setTimeout(() => {
				iconsArray.map((i) => i.classList.remove("fade"));

				setTimeout(() => {
					//zoom into stuff and use masks
					this.introPortal.classList.add("intro-portal-outro");
					this.introOutsidePortal.classList.add("intro-portal-outro");
					this.loaderBg.classList.add("loader-outro");
					// let precision = 32;
					// let radius = 100;
					// let c = [...Array(precision)].map((_, i) => {
					//     let a = -i / (precision - 1) * Math.PI * 2;
					//     let x = Math.cos(a) / 1.5 * radius + 50;
					//     let y = Math.sin(a) * radius + 50;
					//     return `${x}% ${y}%`
					// });
					// this.loaderBg.style.clipPath = `polygon(100% 50%, 100% 100%, 0 100%, 0 0, 100% 0, 100% 50%, ${c.join(',')})`;
					setTimeout(() => {
						this.loaderBg.classList.add("hidden");
					}, 2000);
				}, 2000);
			}, 500);
		}, 500);
	}

	render() {
		return (
			<div ref={(ref) => (this.loaderBg = ref)} className="loader">
				<div ref={(ref) => (this.introPortal = ref)} className="intro-portal">
					<div
						ref={(ref) => (this.loaderLine = ref)}
						className="intro-portal-progress-bg"
					></div>
					<div className="intro-people intro-icon fade">
						<div parallax-rate={-3} className="intro-people-img parallax"></div>
					</div>
					<div className="intro-leaves intro-icon fade">
						<div parallax-rate={-2} className="intro-leaves-img parallax"></div>
					</div>
					<div className="intro-skyline intro-icon fade">
						<div parallax-rate={-1} className="intro-skyline-img parallax"></div>
					</div>
					<div className="intro-flower intro-icon fade">
						<div parallax-rate={-4} className="intro-flower-img parallax"></div>
					</div>
					<div className="intro-palm intro-icon fade">
						<div parallax-rate={-3} className="intro-palm-img parallax"></div>
					</div>
					<div className="intro-bike intro-icon fade">
						<div parallax-rate={1} className="intro-bike-img parallax"></div>
					</div>
					<div className="intro-burger intro-icon fade">
						<div parallax-rate={2} className="intro-burger-img parallax"></div>
					</div>

					<div className="intro-circle intro-icon fade">
						<div parallax-rate={-5} className="intro-circle-img parallax"></div>
					</div>
				</div>
				<div
					ref={(ref) => (this.introOutsidePortal = ref)}
					className="intro-outside-portal"
				>
					<div className="intro-bee intro-icon fade">
						<div parallax-rate={6} className="intro-bee-img parallax"></div>
					</div>
					<div className="intro-butterfly intro-icon fade">
						<div parallax-rate={7} className="intro-butterfly-img parallax"></div>
					</div>
					<div className="intro-tree intro-icon fade">
						<div parallax-rate={3} className="intro-tree-img parallax"></div>
					</div>
				</div>
				<div ref={(ref) => (this.introCont = ref)} className="intro-loader-container">
					<div className="loader-logo"></div>
					<div ref={(ref) => (this.loadingDiv = ref)} className="loading-div fade">
						<div className="loading-spinner" />
						<div className="loading-text">ONE SEC, WE'RE JUST...</div>
						<div
							ref={(ref) => (this.loadingText = ref)}
							className="loading-text green-text"
						>
							RETICULATING SPLINES
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Loader;
