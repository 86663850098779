import React, { Component } from "react";
import { G } from "../globals";
import { amenityData } from "../data/amenityData";
import AmenityGridItem from "./amenityGridItem";

class Amenities extends Component {
  render() {
    return (
      <div className="slide">
        <div className="slide-content  ">
          <div className="slide-panel amenity-panel clickable">
            <div className="amenity-slide-top full-width">
              <div className="amenities-gfk1"></div>
              <div className="amenities-hero">
                <div className="amenities-hero-img"></div>
                <div className="amenities-hero-copy">
                  <h1 className="slide-header">
                    BEYOND
                    <br />
                    <span className="fold-outline">THE FOLD</span>
                  </h1>
                  <div className="amenity-slide-copy">
                    Independent eats, unique hidden gems and cultural icons -
                    they’re all on your doorstep. Here’s our pick of the best in
                    the area.
                  </div>
                </div>
              </div>
            </div>

            <div className="amenity-img-hero ">
              <div className="amenity-img-left">
                <AmenityGridItem
                  content={amenityData.olympia}
                  GoToState={this.props.GoToState}
                  state={"olympia"}
                  isExtended={true}
                />
              </div>
            </div>

            <div className="amenity-2col">
              <div className="amenity-major amenity-major-full">
                <AmenityGridItem
                  content={amenityData.curtains}
                  GoToState={this.props.GoToState}
                  state={"curtains"}
                />
              </div>
            </div>

            <div className="amenity-2col">
              <div className="amenity-minor-cont">
                <AmenityGridItem
                  content={amenityData.barons}
                  GoToState={this.props.GoToState}
                  state={"barons"}
                />
              </div>

              <div className="amenity-major">
                <AmenityGridItem
                  content={amenityData.bonjour}
                  GoToState={this.props.GoToState}
                  state={"bonjour"}
                />
              </div>
            </div>

            <div className="amenity-img-hero amenity-img-hero-right">
              <div className="amenity-img-left">
                <AmenityGridItem
                  content={amenityData.chapter}
                  GoToState={this.props.GoToState}
                  state={"chapter"}
                  isExtended={true}
                />
              </div>
            </div>

            <div className="amenity-2col">
              <div className="amenity-major">
                <AmenityGridItem
                  content={amenityData.bite}
                  state={"bite"}
                  GoToState={this.props.GoToState}
                />
              </div>
              <div className="amenity-minor-cont">
                <AmenityGridItem
                  content={amenityData.underground}
                  GoToState={this.props.GoToState}
                  state={"underground"}
                />
              </div>
            </div>

            <div className="amenity-2col">
              <div className="amenity-minor-cont">
                <AmenityGridItem
                  content={amenityData.tcr}
                  state={"tcr"}
                  GoToState={this.props.GoToState}
                />
              </div>
              <div className="amenity-major">
                <AmenityGridItem
                  content={amenityData.cumberland}
                  GoToState={this.props.GoToState}
                  state={"cumberland"}
                />
              </div>
            </div>
          </div>
          <div className="slide-sub-panel"></div>
        </div>
      </div>
    );
  }
}

export default Amenities;
