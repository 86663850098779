import React, { Component } from "react";
import { G } from "../globals";

class OverlayWellness extends Component {
  constructor() {
    super();

    this.gallery = {
      currentSlide: 0,
      textDiv: null,
      imgDiv: null,
      thumbDiv: null,
    };

    this.isTouched = false;
    this.timeoutId = null;

    this.content = [
      {
        img: "./images/wellness/wellness_terraces.jpg",
        text: ["COMMUNAL AND ", "PRIVATE TERRACES", "ON FIFTH FLOOR"],
      },
      {
        img: "./images/wellness/wellness_courtyard.jpg",
        text: ["COMMUNAL", "GARDENS"],
      },
      {
        img: "./images/wellness/wellness_gym.jpg",
        text: ["GYM WITHIN", "CAMPUS"],
      },
      {
        img: "./images/wellness/wellness_well.jpg",
        text: ["WELL GOLD"],
      },
      {
        img: "./images/wellness/wellness_natural.jpg",
        text: ["EXCELLENT", "NATURAL LIGHT"],
      },
    ];
  }

  autoFlick() {
    if (this.isTouched) return;
    this.timeoutId = setTimeout(() => {
      this.navigateSlides("next");
      this.autoFlick();
    }, 3000);
  }

  disableFlick() {
    this.isTouched = true;
    clearTimeout(this.timeoutId);
  }

  componentDidMount() {
    this.gallery.textDiv = this.galleryText;
    this.gallery.thumbDiv = this.thumbDiv;
    this.gallery.imgDiv = this.galleryImage;
    this.jumpToSlide(0);
    this.autoFlick();
  }

  jumpToSlide(index) {
    const currentSlide = this.content[this.gallery.currentSlide];

    //do outro
    this.gallery.imgDiv.classList.add("outro");

    setTimeout(() => {
      //do reset
      this.gallery.imgDiv.classList.add("reset");
      this.gallery.imgDiv.classList.add("intro");
      this.gallery.imgDiv.classList.remove("outro");
      setTimeout(() => {
        //switch image and add intro
        this.gallery.imgDiv.style.backgroundImage = `url(${currentSlide.img})`;

        //populate text

        // this.gallery.textDiv.innerHTML = this.populateText().filter((t) => t !== ",");
        this.gallery.textDiv.innerHTML = "";
        currentSlide.text.map((t, i) => {
          const li = document.createElement("li");
          li.classList.add("wellness-gallery-text-item");
          const div = document.createElement("div");
          div.classList.add("wellness-gallery-text");
          div.innerHTML = t;
          li.appendChild(div);
          this.gallery.textDiv.appendChild(li);
        });

        setTimeout(() => {
          //do intro
          this.gallery.imgDiv.classList.remove("reset");
          this.gallery.imgDiv.classList.remove("intro");
          const thumbs = Array.from(this.gallery.thumbDiv.children);
          thumbs.map((thumb) => thumb.classList.remove("thumb-active"));
          thumbs[index].classList.add("thumb-active");
        }, 100);
      }, 100);
    }, 500);
  }

  navigateSlides(_dir) {
    let dir = 0;

    if (_dir === "next") {
      dir = +1;
    } else if (_dir === "prev") {
      dir = -1;
    }
    this.gallery.currentSlide += dir;

    if (this.gallery.currentSlide > this.content.length - 1) {
      this.gallery.currentSlide = 0;
    } else if (this.gallery.currentSlide < 0) {
      this.gallery.currentSlide = this.content.length - 1;
    }

    this.jumpToSlide(this.gallery.currentSlide);
  }

  populateText() {
    const currentSlide = this.content[this.gallery.currentSlide];
    let result = [];

    currentSlide.text.map((t, i) => {
      const li = `<li class="wellness-gallery-text-item" key=${i}>
					<div class="wellness-gallery-text">${t}</div>
				</li>`;
      result.push(li);
    });

    return result;
  }

  render() {
    return (
      <div className="overlay clickable">
        <div className="overlay-bg"></div>
        <div className="overlay-content wellness-content">
          <div className="overlay-col-left">
            <div
              className="return-button"
              onClick={() => G.CloseOverlay("showWellness")}
            >
              Return
            </div>
            <div className="overlay-sub-title">Wellness</div>
            <div className="overlay-copy">
              Wellness is key at The Fold. Key features have been considered
              with occupiers in mind whilst the architectural environment
              creates a continued sense of satisfaction and inspiration.
            </div>
          </div>
          <div className="overlay-col-right">
            <div className="wellness-gallery">
              <div
                ref={(ref) => (this.galleryImage = ref)}
                className="wellness-gallery-image"
              ></div>
              <ul
                ref={(ref) => (this.galleryText = ref)}
                className="wellness-gallery-text-list w-list-unstyled"
              ></ul>
              <div
                ref={(ref) => (this.thumbDiv = ref)}
                className="wellness-thumb-container"
              >
                {this.content.map((t, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => this.jumpToSlide(i)}
                      className={`thumb ${
                        this.gallery.currentSlide === i ? "thumb-active" : ""
                      }`}
                    ></div>
                  );
                })}
              </div>
              <div className="wellness-gallery-controls">
                <div
                  className="wellness-gallery-button prev-button"
                  onClick={() => {
                    if (!this.isTouched) this.disableFlick();
                    this.navigateSlides("prev");
                  }}
                ></div>
                <div
                  className="wellness-gallery-button next-button"
                  onClick={() => {
                    if (!this.isTouched) this.disableFlick();
                    this.navigateSlides("next");
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className="overlay-close"
            onClick={() => G.CloseOverlay("showWellness")}
          ></div>
        </div>
      </div>
    );
  }
}

export default OverlayWellness;
