import * as THREE from "three";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { G } from "../globals";
import Entity from "./Entity";
import FeatureMarker from "./markers/featureMarker";
import TextLabel from "./markers/textLabel";
import FloorMarker from "./markers/floorMarker";
import AmenityLabel from "./markers/amenityLabel";
import {
  foldMarker,
  overviewMarkers,
  connectionOlympiaMarker,
  amenityMarkers,
  transpStations,
  floorMarkersFifth,
  floorMarkersFourth,
  floorMarkersThird,
  floorMarkersSecond,
  floorMarkersFirst,
  floorMarkersLG,
} from "../data/markerData";

class MarkerController extends Entity {
  constructor() {
    super();

    const textureLoader = new THREE.TextureLoader();
    const fontLoader = new FontLoader();

    this.ToggleMarkers = this.ToggleMarkers.bind(this);

    G.ToggleMarkers = this.ToggleMarkers;
    G.ShowSpecificMarker = this.ShowSpecificMarker;

    this.markers = {};

    this.markersToCreate = [
      {
        name: "foldMarker",
        data: foldMarker,
        marker: TextLabel,
        forceScale: 3,
      },
      {
        name: "overviewMarkers",
        data: overviewMarkers,
        marker: TextLabel,
        forceScale: 3,
      },
      {
        name: "connectionOlympiaMarker",
        data: connectionOlympiaMarker,
        marker: TextLabel,
        forceScale: 5,
      },
      {
        name: "featureAmenityMarkers",
        data: amenityMarkers,
        marker: AmenityLabel,
        forceScale: 1,
      },

      {
        name: "transpStations",
        data: transpStations,
        marker: TextLabel,
        forceScale: 4,
      },

      {
        name: "floorMarkersFifth",
        data: floorMarkersFifth,
        marker: FloorMarker,
        forceScale: 4,
        icon: "floorImageMarker",
        iconActive: "floorImageMarkerHover",
        iconSize: { width: 10, height: 10 },
      },

      {
        name: "floorMarkersFourth",
        data: floorMarkersFourth,
        marker: FloorMarker,
        forceScale: 4,
        icon: "floorImageMarker",
        iconActive: "floorImageMarkerHover",
        iconSize: { width: 10, height: 10 },
      },

      {
        name: "floorMarkersThird",
        data: floorMarkersThird,
        marker: FloorMarker,
        forceScale: 4,
        icon: "floorImageMarker",
        iconActive: "floorImageMarkerHover",
        iconSize: { width: 10, height: 10 },
      },

      {
        name: "floorMarkersSecond",
        data: floorMarkersSecond,
        marker: FloorMarker,
        forceScale: 4,
        icon: "floorImageMarker",
        iconActive: "floorImageMarkerHover",
        iconSize: { width: 10, height: 10 },
      },

      {
        name: "floorMarkersFirst",
        data: floorMarkersFirst,
        marker: FloorMarker,
        forceScale: 4,
        icon: "floorImageMarker",
        iconActive: "floorImageMarkerHover",
        iconSize: { width: 10, height: 10 },
      },

      {
        name: "floorMarkersLG",
        data: floorMarkersLG,
        marker: FloorMarker,
        forceScale: 4,
        icon: "floorImageMarker",
        iconActive: "floorImageMarkerHover",
        iconSize: { width: 10, height: 10 },
      },
    ];

    this.assetsManifest = [
      { name: "font", loader: fontLoader, path: "fonts/circularBold.json" },
      {
        name: "foldIcon",
        loader: textureLoader,
        path: "images/fold-icon.png",
      },

      {
        name: "floorImageMarker",
        loader: textureLoader,
        path: "images/floorMarkerIcon.png",
      },

      {
        name: "floorImageMarkerHover",
        loader: textureLoader,
        path: "images/floorMarkerIconHover.png",
      },

      {
        name: "featChapterCoffee",
        loader: textureLoader,
        path: "images/feat_chapterCoffee.png",
      },

      {
        name: "featBarons",
        loader: textureLoader,
        path: "images/feat_barons.png",
      },

      {
        name: "featUnderground",
        loader: textureLoader,
        path: "images/feat_coffeeUnderground.png",
      },

      {
        name: "featBonjour",
        loader: textureLoader,
        path: "images/feat_bonjour.png",
      },

      {
        name: "featTcr",
        loader: textureLoader,
        path: "images/feat_tcr.png",
      },

      {
        name: "featCurtains",
        loader: textureLoader,
        path: "images/feat_curtains.png",
      },

      {
        name: "featBite",
        loader: textureLoader,
        path: "images/feat_bite.png",
      },

      {
        name: "featCumberland",
        loader: textureLoader,
        path: "images/feat_cumberland.png",
      },

      {
        name: "featOlympia",
        loader: textureLoader,
        path: "images/feat_olympia.png",
      },

      {
        name: "featOlympiaActive",
        loader: textureLoader,
        path: "images/feat_olympia-active.png",
      },

      {
        name: "featChapterCoffeeActive",
        loader: textureLoader,
        path: "images/feat_chapterCoffee-active.png",
      },

      {
        name: "featBaronsActive",
        loader: textureLoader,
        path: "images/feat_barons-active.png",
      },

      {
        name: "featUndergroundActive",
        loader: textureLoader,
        path: "images/feat_coffeeUnderground-active.png",
      },

      {
        name: "featBonjourActive",
        loader: textureLoader,
        path: "images/feat_bonjour-active.png",
      },

      {
        name: "featTcrActive",
        loader: textureLoader,
        path: "images/feat_tcr-active.png",
      },

      {
        name: "featCurtainsActive",
        loader: textureLoader,
        path: "images/feat_curtains-active.png",
      },

      {
        name: "featBiteActive",
        loader: textureLoader,
        path: "images/feat_bite-active.png",
      },

      {
        name: "featCumberlandActive",
        loader: textureLoader,
        path: "images/feat_cumberland-active.png",
      },

      {
        name: "featf45",
        loader: textureLoader,
        path: "images/feat_f45.png",
      },

      {
        name: "transp_WestKensington",
        loader: textureLoader,
        path: "images/transp_WestKensington.png",
      },

      {
        name: "transp_Barons",
        loader: textureLoader,
        path: "images/transp_Barons.png",
      },

      {
        name: "transp_Earls",
        loader: textureLoader,
        path: "images/transp_Earls.png",
      },

      {
        name: "transp_Olympia",
        loader: textureLoader,
        path: "images/transp_Olympia.png",
      },

      {
        name: "transp_Hammersmith",
        loader: textureLoader,
        path: "images/transp_Hammersmith.png",
      },

      {
        name: "transp_Brompton",
        loader: textureLoader,
        path: "images/transp_Brompton.png",
      },

      {
        name: "transp_walk6min",
        loader: textureLoader,
        path: "images/transp_walk6min.png",
      },
    ];

    this.CreateMarkers();
    setTimeout(() => {
      this.HideMarkers();
    }, 3000);
  }

  CreateMarkers() {
    this.LoadAssets().then((loadedAssets) => {
      this.loadedAssets = loadedAssets;
      G.loadedAssets = loadedAssets;
      this.markersToCreate.map((newMarker) => {
        newMarker.groupName = `${newMarker.name}Group`;
        newMarker.group = new THREE.Group();
        this.markers[newMarker.groupName] = newMarker;

        if (newMarker.data && Array.isArray(newMarker.data)) {
          newMarker.m = newMarker.data.map((entry, i) => {
            const m = new newMarker.marker({
              font: loadedAssets.font ? loadedAssets.font : null,
              name: newMarker.name,
              id: entry.id ? entry.id : null,
              markerData: entry,
              image: loadedAssets[entry.image],
              margin: newMarker.margin,
              height: newMarker.height
                ? newMarker.height
                : entry.height
                ? entry.height
                : 6,
              iconSize: newMarker.iconSize
                ? newMarker.iconSize
                : entry.iconSize
                ? entry.iconSize
                : null,
              icon: loadedAssets[entry.icon]
                ? loadedAssets[entry.icon]
                : loadedAssets[newMarker.icon]
                ? loadedAssets[newMarker.icon]
                : null,
              thumbImg: entry.thumbImg ? loadedAssets[entry.thumbImg] : null,
              activeIcon: loadedAssets[newMarker.activeIcon],
              occupiers: entry.occupiers
                ? entry.occupiers.map((o) => {
                    const img = loadedAssets[o];
                    if (!img) console.error("CAN'T LOAD ASSET " + o);
                    return img;
                  })
                : null,
              color: newMarker.color,
              textColor: newMarker.textColor,
              activeColor: newMarker.activeColor,
              activeTextColor: newMarker.activeTextColor,
              showMode: newMarker.showMode ? newMarker.showMode : null,
              proximityDist: newMarker.proximityDist
                ? newMarker.proximityDist
                : null,
              forceScale: newMarker.forceScale
                ? newMarker.forceScale
                : entry.forceScale
                ? entry.forceScale
                : 1,
            });
            newMarker.group.add(m.marker);
            newMarker.group.isVisible = false;
            return m;
          });
        } else {
          // Check if marker is a promise by seeing if it has a then function (eg if we are loading models);
          newMarker.m = new newMarker.marker({
            font: loadedAssets.font ? loadedAssets.font : null,
            name: newMarker.name,
          }).then((loadedMarker) => {
            newMarker.group.add(loadedMarker);
            return loadedMarker;
          });
        }

        G.markers[newMarker.name] = newMarker;
        this.Instantiate(newMarker.group, newMarker.groupName, false, true);
        return null;
      });
    });
  }

  LoadAssets() {
    const promiseArray = this.assetsManifest.map((asset) => {
      return new Promise((resolve, reject) => {
        asset.loader.load(asset.path, (data) => {
          resolve(data);
        });
      });
    });

    return Promise.all(promiseArray).then((loadedAssets) => {
      const assets = {};
      this.assetsManifest.map((asset, i) => {
        assets[asset.name] = loadedAssets[i];
        return null;
      });

      return assets;
    });
  }

  onStateChange(newState) {
    this.HideMarkers();
  }
  onCamDone(newState) {
    if (newState.markers) {
      this.ToggleMarkers(newState.markers, true);
    }
  }

  HideMarkers() {
    [...Object.values(this.markers)].map((marker) => {
      if (Array.isArray(G.markers[marker.name].m))
        G.markers[marker.name].m.map((m) => (m.isVisible = false));
      G.markers[marker.name].group.visible = false;
      return null;
    });
  }

  ToggleMarkers(markersToToggle, bool = true) {
    if (Array.isArray(markersToToggle)) {
      markersToToggle.map((marker) => {
        if (G.markers[marker]) {
          G.markers[marker].group.visible = bool;
          if (Array.isArray(G.markers[marker].m))
            G.markers[marker].m.map((m) => (m.isVisible = bool));
        } else console.error("UNABLE TO FIND MARKER WITH STRING - " + marker);
        return null;
      });
    } else {
      if (G.markers[markersToToggle]) {
        G.markers[markersToToggle].group.visible = bool;
        if (Array.isArray(G.markers[markersToToggle].m))
          G.markers[markersToToggle].m.map((m) => (m.isVisible = bool));
      } else
        console.error("UNABLE TO FIND MARKER WITH STRING - " + markersToToggle);
      return null;
    }
  }

  ShowSpecificMarker(id, collection) {
    if (!G.markers[collection])
      return console.error(
        "SHOW SPECIFIC MARKER: UNABLE TO FIND COLLECTION " + collection
      );

    this.ToggleMarkers(collection);
    const marker = G.markers[collection].m.find((bM) => bM.params.id === id);
    if (marker) {
      marker.isVisible = true;
      return marker;
    } else
      console.error(
        "UNABLE TO FIND MARKER " + id + " IN COLLECTION " + collection
      );
  }
}

export default MarkerController;
