import React, { Component } from "react";
import OverlayGallery from "./overlayGallery";
import OverlayVirtual from './overlayVirtual';
import OverlayPlanView from "./overlayPlan";
import OverlaySustainability from "./overlaySustainability";
import OverlayWellness from "./overlayWellness";
import { G } from "../globals";
import OverlaySpecification from "./overlaySpecification";

class Overlays extends Component {
	constructor() {
		super();

		this.OpenOverlay = this.OpenOverlay.bind(this);
		this.CloseOverlay = this.CloseOverlay.bind(this);

		G.OpenOverlay = this.OpenOverlay;
		G.CloseOverlay = this.CloseOverlay;

		this.state = {
			showGallery: null,
			showVirtual: null,
			showWellness: null,
			showSust: null,
			showSpec: null,
			showPlan: null,
		};
	}

	OpenOverlay(overlay, data) {
		console.log("open!!");
		this.setState({ [overlay]: data });
	}

	CloseOverlay(overlay) {
		this.setState({ [overlay]: null });
	}

	render() {
		return (
			<div className="overlays">
				{this.state.showGallery ? (
					<OverlayGallery
						content={this.state.showGallery}
						OpenOverlay={this.OpenOverlay}
						CloseOverlay={this.CloseOverlay}
					/>
				) : null}
				{this.state.showWellness ? (
					<OverlayWellness
						content={this.state.showWellness}
						OpenOverlay={this.OpenOverlay}
						CloseOverlay={this.CloseOverlay}
					/>
				) : null}
				{this.state.showSpec ? (
					<OverlaySpecification
						content={this.state.showSpec}
						OpenOverlay={this.OpenOverlay}
						CloseOverlay={this.CloseOverlay}
					/>
				) : null}
				{this.state.showSust ? (
					<OverlaySustainability
						content={this.state.showSust}
						OpenOverlay={this.OpenOverlay}
						CloseOverlay={this.CloseOverlay}
					/>
				) : null}
				{this.state.showPlan ? (
					<OverlayPlanView
						content={this.state.showPlan}
						OpenOverlay={this.OpenOverlay}
						CloseOverlay={this.CloseOverlay}
					/>
				) : null}
					{this.state.showVirtual ? (
					<OverlayVirtual
						content={this.state.showVirtual}
						OpenOverlay={this.OpenOverlay}
						CloseOverlay={this.CloseOverlay}
					/>
				) : null}
			</div>
		);
	}
}

export default Overlays;
