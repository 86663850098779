import React, { Component } from "react";
import VimeoPlayer from "@vimeo/player";

class Intro extends Component {
  constructor() {
    super();

    this.introAnim = this.introAnim.bind(this);
    this.outroAnim = this.outroAnim.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    document.addEventListener("loadDone", this.introAnim);
    document.addEventListener("stateWillChange", this.outroAnim);
  }

  componentDidMount() {
    // Add event listeners for modal close when clicking outside the modal
    window.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener("loadDone", this.introAnim);
    document.removeEventListener("stateWillChange", this.outroAnim);
    window.removeEventListener("click", this.handleOutsideClick);
  }

  introAnim() {
    setTimeout(() => {
      this.introImg.classList.remove("intro-hero-img-init");
      setTimeout(() => {
        const fadeItems = [
          ...this.slideDiv.querySelectorAll(".intro-slide-item"),
        ];
        fadeItems.map((f) => f.classList.remove("fade"));
      }, 600);
    }, 3000);
  }

  outroAnim() {
    this.slideDiv.classList.add("start-outro");
    this.introImg.classList.add("intro-hero-img-outro");
  }

  openModal() {
    // Show the modal
    this.modal.style.display = "flex";

    // Initialize Vimeo player and play the video
    this.player = new VimeoPlayer(this.vimeoIframe);
    this.player.play();
  }

  closeModal() {
    // Hide the modal and pause the video
    this.modal.style.display = "none";
    if (this.player) {
      this.player.pause();
    }
  }

  handleOutsideClick(event) {
    if (event.target === this.modal) {
      this.closeModal();
    }
  }

  render() {
    return (
      <div>
        <div
          ref={(ref) => (this.slideDiv = ref)}
          className="slide clickable start-init"
        >
          <div className="intro-hero-container">
            <div
              ref={(ref) => (this.introImg = ref)}
              className="intro-hero-img intro-hero-img-init"
            >
              <video
                className="intro-video"
                src="https://files.sirendigital.uk/bgloop.mp4"
                autoPlay
                loop
                muted
                playsInline
                controls={false}
              ></video>
            </div>
            <div className="intro-fold-logo intro-slide-item fade"></div>
            <div
              className="intro-start-button intro-slide-item fade"
              onClick={() => this.props.GoToState("OVERVIEW", 1000)}
            >
              START
            </div>
            <div
              className="intro-start-button completion intro-slide-item fade"
              onClick={this.openModal}
            >
              PLAY COMPLETION VIDEO
            </div>
            <div className="intro-sub-container intro-slide-item fade">
              <div className="intro-sub-content">
                87,174 sq ft brand new workspace <br />
                in Kensington Village
              </div>
            </div>
          </div>
        </div>

        {/* Modal Structure */}
        <div
          id="myModal"
          ref={(ref) => (this.modal = ref)}
          className="video-modal"
          style={{ display: "none" }}
        >
          <span className="close" onClick={this.closeModal}>
            &times;
          </span>
          <div className="modal-content">
            {/* Vimeo Video */}
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                ref={(ref) => (this.vimeoIframe = ref)}
                src="https://player.vimeo.com/video/1003538152?h=2f3c75db5f&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="The Fold Viewcast"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
