import U from "../utils";
import * as THREE from "three";

export const foldMarker = [
  {
    icon: "foldIcon",
    iconSize: { width: 6, height: 6 },
    location: { x: 17.21, y: 10, z: -354.613 },
    height: 50,
    pinPointHeight: 3,
  },
];

export const connectionOlympiaMarker = [
  {
    text: "Olympia London",
    iconSize: { width: 6, height: 6 },
    location: { x: -2666.508, y: 6.9, z: -2637.645 },
    height: 30,
    fontSize: 0.75,
    pinPointHeight: 1,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
];

export const overviewMarkers = [
  {
    text: "Pembroke Building",
    iconSize: { width: 6, height: 6 },
    location: { x: -131.961, y: 6.9, z: -185.946 },
    height: 50,
    fontSize: 0.75,
    pinPointHeight: 3,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
  {
    text: "Abingdon & Spur",
    iconSize: { width: 6, height: 6 },
    location: { x: -235.54, y: 6.9, z: -573.59 },
    height: 30,
    fontSize: 0.75,
    pinPointHeight: 3,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
  {
    text: "CACI",
    iconSize: { width: 6, height: 6 },
    location: { x: -456.581, y: 6.9, z: -860.241 },
    height: 30,
    fontSize: 0.75,
    pinPointHeight: 3,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
  {
    text: "Avon House",
    iconSize: { width: 6, height: 6 },
    location: { x: -64.203, y: 6.9, z: -628.523 },
    height: 60,
    fontSize: 0.75,
    pinPointHeight: 3,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
  {
    text: "Beaumont House",
    iconSize: { width: 6, height: 6 },
    location: { x: -151.42, y: 6.9, z: -788.319 },
    height: 40,
    fontSize: 0.75,
    pinPointHeight: 3,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
  {
    text: "Gloucester Building",
    iconSize: { width: 6, height: 6 },
    location: { x: -396.24, y: 6.9, z: -394.45 },
    height: 40,
    fontSize: 0.75,
    pinPointHeight: 3,
    zOffset: 1, //gap between marker's content and the top of the pinpoint line
  },
];

export const amenityMarkers = [
  {
    text: "Olympia Development",
    color: "#ffffff",
    outline: "#06262D",
    id: "olympia",
    icon: "featOlympia",
    iconActive: "featOlympia",
    iconSize: { width: 19.7, height: 14 },
    location: { x: -2632.473, y: 6.9, z: -2664.604 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "Chapter Coffee",
    color: "#ffffff",
    outline: "#06262D",
    id: "chapter",
    icon: "featChapterCoffee",
    iconActive: "featChapterCoffeeActive",
    iconSize: { width: 19.7, height: 14 },
    location: { x: -1403.423, y: 6.9, z: -301.906 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "Barons Florist",
    color: "#ffffff",
    outline: "#06262D",
    id: "barons",
    icon: "featBarons",
    iconActive: "featBaronsActive",
    iconSize: { width: 15.3, height: 15.6 },
    location: { x: -1288.358, y: 6.9, z: 711.507 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "Coffee Underground",
    color: "#ffffff",
    outline: "#06262D",
    id: "underground",
    icon: "featUnderground",
    iconActive: "featUndergroundActive",
    iconSize: { width: 15.3, height: 15.6 },
    location: { x: -1230.938, y: 6.9, z: 446.64 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "Bonjour Brioche",
    color: "#ffffff",
    outline: "#06262D",
    id: "bonjour",
    icon: "featBonjour",
    iconActive: "featBonjourActive",
    iconSize: { width: 21.6, height: 14 },
    location: { x: -1072.542, y: 6.9, z: 834.6 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "TCR Barbers",
    color: "#ffffff",
    outline: "#06262D",
    id: "tcr",
    icon: "featTcr",
    iconActive: "featTcrActive",
    iconSize: { width: 15.3, height: 20.1 },
    location: { x: -1485.765, y: 6.9, z: 786.114 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "The Curtains Up",
    color: "#ffffff",
    outline: "#06262D",
    id: "curtains",
    icon: "featCurtains",
    iconActive: "featCurtainsActive",
    iconSize: { width: 14.2, height: 17.4 },
    location: { x: -2235.905, y: 6.9, z: 1137.592 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "Bite",
    color: "#ffffff",
    outline: "#06262D",
    id: "bite",
    icon: "featBite",
    iconActive: "featBiteActive",
    iconSize: { width: 17, height: 14 },
    location: { x: -2179.134, y: 6.9, z: -1165.612 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "The Cumberland Arms",
    color: "#ffffff",
    outline: "#06262D",
    id: "cumberland",
    icon: "featCumberland",
    iconActive: "featCumberlandActive",
    iconSize: { width: 15.2, height: 15.8 },
    location: { x: -2419.025, y: 6.9, z: -1594.052 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
  {
    text: "F45 Kensington",
    id: "f45",
    icon: "featf45",
    iconActive: "featf45",
    iconSize: { width: 17.4, height: 14 },
    location: { x: -3191.607, y: 6.9, z: -1737.318 },
    height: 3,
    // data: "FEATURE_HEALTHY",
  },
];

export const transpStations = [
  {
    icon: "transp_WestKensington",
    iconSize: { width: 16.7, height: 3.8 },
    location: { x: -1192.319, y: 6.9, z: 397.942 },
    height: 5,
    pinPointHeight: 1,
    zOffset: 2,
  },
  {
    icon: "transp_Barons",
    iconSize: { width: 14.5, height: 3.8 },
    location: { x: -3799.38, y: 6.9, z: 621.834 },
    height: 5,
    pinPointHeight: 1,
    zOffset: 2,
  },
  {
    icon: "transp_Earls",
    iconSize: { width: 13.3, height: 3.8 },
    location: { x: 2414.402, y: 6.9, z: 429.241 },
    height: 5,
    pinPointHeight: 1,
    zOffset: 2,
  },
  {
    icon: "transp_Olympia",
    iconSize: { width: 19.9, height: 3.8 },
    location: { x: -1979.858, y: 6.9, z: -2951.95 },
    height: 5,
    pinPointHeight: 1,
    zOffset: 2,
  },
  {
    icon: "transp_Hammersmith",
    iconSize: { width: 14.5, height: 3.8 },
    location: { x: -6805.483, y: 6.9, z: -111.446 },
    height: 5,
    pinPointHeight: 1,
    zOffset: 2,
  },
  // {
  // 	icon: "transp_Brompton",
  // 	iconSize: { width: 9.9, height: 2.8 },
  // 	location: { x: 2551.369, y: 6.9, z: 2391.291 },
  // 	height: 5,
  // 	pinPointHeight: 1,
  // 	zOffset: 2
  // },
];

export const floorMarkersFifth = [
  {
    location: { x: 57.153, y: 121.5, z: -352.294 },
    images: [
      {
        img: "./images/gallery/comp/floor-5.jpg",
        caption: "5th floor",
      },
      {
        img: "./images/gallery/comp/floor-5-2.jpg",
        caption: "5th floor",
      },
    ],
  },
  {
    location: { x: 74.23, y: 121.5, z: -234.911 },
    images: [
      {
        img: "./images/gallery/comp/terrace-1.jpg",
        caption: "Communal 5th floor terrace",
      },
      {
        img: "./images/gallery/comp/terrace-2.jpg",
        caption: "Communal 5th floor terrace",
      },
      {
        img: "./images/gallery/comp/terrace-3.jpg",
        caption: "Communal 5th floor terrace",
      },
      {
        img: "./images/gallery/comp/terrace-4.jpg",
        caption: "Communal 5th floor terrace",
      },
      {
        img: "./images/gallery/comp/terrace-5.jpg",
        caption: "Communal 5th floor terrace",
      },
    ],
  },
];

export const floorMarkersFourth = [
  {
    location: { x: 17.522, y: 96.2, z: -425.031 },
    images: [
      {
        img: "./images/gallery/comp/floor-4.jpg",
        caption: "4th Floor",
      },
      {
        img: "./images/gallery/comp/floor-4-2.jpg",
        caption: "4th Floor",
      },
      {
        img: "./images/gallery/comp/typical-1.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-2.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-3.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-6.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-4.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-7.jpg",
        caption: "Typical Floor",
      },
    ],
  },
];

export const floorMarkersThird = [
  {
    location: { x: 15.311, y: 77.2, z: -427.671 },
    images: [
      {
        img: "./images/gallery/comp/floor-3.jpg",
        caption: "3rd Floor",
      },
      {
        img: "./images/gallery/comp/typical-1.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-2.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-3.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-6.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-4.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-7.jpg",
        caption: "Typical Floor",
      },
    ],
  },
];

export const floorMarkersSecond = [
  {
    location: { x: 13.783, y: 58.2, z: -427.511 },
    images: [
      {
        img: "./images/gallery/comp/floor-2.jpg",
        caption: "2nd Floor",
      },
      {
        img: "./images/gallery/comp/typical-1.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-2.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-3.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-6.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-4.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-7.jpg",
        caption: "Typical Floor",
      },
    ],
  },
];

export const floorMarkersFirst = [
  {
    location: { x: 13.575, y: 41.2, z: -425.369 },
    images: [
      {
        img: "./images/gallery/comp/floor-1.jpg",
        caption: "1st Floor",
      },
      {
        img: "./images/gallery/comp/floor-1-2.jpg",
        caption: "1st Floor",
      },
      {
        img: "./images/gallery/comp/typical-1.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-2.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-3.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-6.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-4.jpg",
        caption: "Typical Floor",
      },
      {
        img: "./images/gallery/comp/typical-7.jpg",
        caption: "Typical Floor",
      },
    ],
  },
];

export const floorMarkersLG = [
  {
    location: { x: -166.645, y: 6.9, z: -422.921 },
    images: [
      {
        img: "./images/gallery/comp/lower-ground-1.jpg",
        caption: "Changing rooms",
      },
    ],
  },
  {
    location: { x: -165.605, y: 6.9, z: -344.448 },
    images: [
      {
        img: "./images/gallery/comp/lower-ground-3.jpg",
        caption: "Changing rooms",
      },
    ],
  },
];
