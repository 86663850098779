export const loaderTextData = [
	"RETICULATING SPLINES",
	"DOING A QUICK COFFEE RUN",
	"SHINING UP THE MODEL",
	"WATERING THE TERRACE TREE",
	"UNPACKING THE SUN LOUNGERS",
	"GETTING A HAIRCUT FROM TCR BARBERS",
	"GRABBING A PINT FROM THE CUMBERLAND ARMS",
	"POLISHING UP ALL 131 BIKE SPACES",
	"CHARGING UP THE EV CHARGERS",
	"GETTING A BRIOCHE FROM BONJOUR. WANT ONE?",
	"GETTING THE GYM READY",
];
