import React, { Component } from "react";

class Contact extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="slide-content">
        <div className="slide-panel contact-panel">
          <div className="contact-content clickable">
            <div className="contact-left">
              <div className="contact-line">Development by</div>
              <div className="axa-logo"></div>
              <div className="download-area">
                <div className="contact-line">Downloads</div>
                <ul className="downloads-list w-list-unstyled">
                  <li className="download-item">
                    <a
                      className="dl"
                      href="dl/TheFold_Brochure.pdf"
                      target="_blank"
                    >
                      Brochure <br />
                      (PDF, 5.8mb)
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="contact-right">
              <div className="contact-names">
                <div className="contact-name-col">
                  <div className="jll-logo"></div>

                  <div className="contact-name">
                    <span className="name">
                      George Reynolds
                      <br />
                    </span>
                    <a href="mailto:george.reynolds@jll.com" className="email">
                      george.reynolds@jll.com
                    </a>
                    <br />
                    +44 7592 112110
                  </div>
                  <div className="contact-name">
                    <span className="name">Joshua Doble</span>
                    <br />
                    <a href="mailto:joshua.doble@jll.com" className="email">
                      joshua.doble@jll.com
                    </a>{" "}
                    <br />
                    +44 7783 771576
                  </div>
                </div>
                <div className="contact-name-col">
                  <div className="colliers-logo"></div>

                  <div className="contact-name">
                    <span className="name">Alex Kemp</span>
                    <br />
                    <a href="mailto:alex.kemp@colliers.com" className="email">
                      alex.kemp@colliers.com
                    </a>
                    <br />
                    +44 7881 610962
                  </div>
                  <div className="contact-name">
                    <span className="name">Conor Walmsley</span>
                    <br />
                    <a
                      href="mailto:conor.walmsley@colliers.com"
                      className="email"
                    >
                      conor.walmsley@colliers.com
                    </a>
                    <br />
                    +44 7716 406211
                  </div>
                  <div className="contact-name">
                    <span className="name">Mark Radford</span>
                    <br />
                    <a
                      href="mailto:mark.radford@colliers.com"
                      className="email"
                    >
                      mark.radford@colliers.com
                    </a>
                    <br />
                    +44 7730 318148
                  </div>
                </div>
              </div>
              <div className="misrep">
                Colliers International, and its joint agent, JLL, give notice
                that these particulars are set out as a general outline only for
                the guidance of intending Purchasers or Lessees and do not
                constitute any part of an offer or contract. Details are given
                without any responsibility and any intending Purchasers, Lessees
                or Third Party should not rely on them as statements or
                representations of fact, but must satisfy themselves by
                inspection or otherwise as to the correctness of each of them.
                No person employed or engaged by Colliers International, or its
                joint agents, has any authority to make any representation or
                warranty whatsoever in relation to this property. Colliers
                International is the licensed trading name of Colliers
                International Property Advisers UK LLP which is a limited
                liability partnership registered in England and Wales with
                registered number OC385143. Our registered office is at 50
                George Street, London W1U 7GA.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
