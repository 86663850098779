import React, { Component } from "react";

class AmenityGridItem extends Component {
	constructor() {
		super();

		this.isActive = false;

		this.timeoutId = null;

		this.enableRotation = this.enableRotation.bind(this);
		this.disableRotation = this.disableRotation.bind(this);

		this.state = {
			currentSlide: 0,
		};
	}

	componentDidMount() {
		if (this.amenityItemDiv) {
			this.amenityItemDiv.addEventListener("mouseenter", this.enableRotation);
			this.amenityItemDiv.addEventListener("mouseleave", this.disableRotation);
		}
	}

	enableRotation() {
		this.isActive = true;
		// setTimeout(() => {
		this.rotateImages();
		// }, 2000);
	}

	disableRotation() {
		this.isActive = false;
		if (this.timeoutId) clearTimeout(this.timeoutId);
		this.setState({ currentSlide: 0 });
	}

	rotateImages() {
		if (!this.isActive) return;
		if (!this.amenityImg) return;

		const newIndex = this.props.content.hero[this.state.currentSlide + 1]
			? this.state.currentSlide + 1
			: 0;

		this.amenityImg.classList.add("fade");

		setTimeout(() => {
			if (!this.amenityImg) return;
			this.setState({ currentSlide: newIndex });
			this.amenityImg.classList.remove("fade");

			this.timeoutId = setTimeout(() => {
				if (this.isActive) this.rotateImages();
			}, 2000);
		}, 200);
	}

	render() {
		return (
			<div
				ref={(ref) => (this.amenityItemDiv = ref)}
				onClick={() => this.props.GoToState(this.props.state.toUpperCase())}
				className={this.props.isExtended ? "amenity-img-left" : "amenity-img-item"}
			>
				<div
					className={`amenity-info-container ${
						this.props.isExtended ? "amenity-info-wide" : ""
					} `}
				>
					<div className="amenity-walk-time">{this.props.content.walkTime}</div>
					<div className="amenity-title-block">
						<h3 className="heading">{this.props.content.title}</h3>
						<div className="amenity-learn-button">Learn more</div>
					</div>
				</div>
				<div className="amenity-info-fade"></div>
				<div
					ref={(ref) => (this.amenityImg = ref)}
					className="amenity-img"
					style={{
						backgroundImage: `url(${this.props.content.hero[this.state.currentSlide]})`,
					}}
				></div>
			</div>
		);
	}
}

export default AmenityGridItem;
