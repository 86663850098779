import featChapterHero from "../images/feat-chapter-hero.jpg";
import featChapter1 from "../images/feat-chapter-img1.jpg";
import featChapter2 from "../images/feat-chapter-img2.jpg";
import featChapter3 from "../images/feat-chapter-img3.jpg";

import featBonjourHero from "../images/feat-bonjour-hero.jpg";

import featBaronsHero from "../images/feat-barons-hero.jpg";
import featBarons1 from "../images/feat-barons-img1.jpg";
import featBarons2 from "../images/feat-barons-img2.jpg";
import featBarons3 from "../images/feat-barons-img3.jpg";

import featCurtainsHero from "../images/feat-curtains-hero.jpg";
import featCurtains1 from "../images/feat-curtains-img1.jpg";
import featCurtains2 from "../images/feat-curtains-img2.jpg";

import featUndergroundHero from "../images/feat-underground-hero.jpg";
import featUnderground1 from "../images/feat-underground-img1.jpg";
import featUnderground2 from "../images/feat-underground-img2.jpg";
import featUnderground3 from "../images/feat-underground-img3.jpg";

import featBiteHero from "../images/feat-bite-hero.jpg";
import featBite2 from "../images/feat-bite-img2.jpg";
import featBite3 from "../images/feat-bite-img3.jpg";

import featTcrHero from "../images/feat-tcr-hero.jpg";
import featTcr1 from "../images/feat-tcr-img1.jpg";
import featTcr2 from "../images/feat-tcr-img2.jpg";
import featTcr3 from "../images/feat-tcr-img3.jpg";
import featTcr4 from "../images/feat-tcr-img4.jpg";

import featCumberlandHero from "../images/feat-cumberland-hero.jpg";
import featCumberland1 from "../images/feat-cumberland-img1.jpg";
import featCumberland2 from "../images/feat-cumberland-img2.jpg";
import featCumberland3 from "../images/feat-cumberland-img3.jpg";

import featOlympicHero from "../images/feat-olympic-hero.jpg";
import featOlympic1 from "../images/feat-olympic-img1.jpg";

import featOlympiaIcon from "../images/feat_olympia.png";
import featChapterIcon from "../images/feat_chapterCoffee.png";
import featBaronsIcon from "../images/feat_barons.png";
import featBiteIcon from "../images/feat_barons.png";
import featBonjourIcon from "../images/feat_bonjour.png";
import featUndergroundIcon from "../images/feat_coffeeUnderground.png";
import featCumberlandIcon from "../images/feat_cumberland.png";
import featCurtainsIcon from "../images/feat_curtains.png";
import featTcrIcon from "../images/feat_tcr.png";

export const amenityData = {
  olympia: {
    title: "Olympia Development",
    desc: `The development of the neighbouring Olympia site will offer even more including live music venue, gym, cinema, theatre and rooftop food and market hall.

		66,000 sq ft roof garden and market hall
		4,400 capacity live music venue
		1,575 seat performing arts theatre
		8,500 sq ft state of the art gym
		2.5 acres public realm
		2 Internationally renowned hotels
		4 screen art house cinema
		4 Major exhibition halls`,
    walkTime: "5 mins",
    icon: featOlympiaIcon,
    hero: [featOlympicHero],
    gallery: [featOlympicHero, featOlympic1],
  },
  curtains: {
    title: "The Curtains Up",
    desc: "The Curtains Up is just a short walk from both Barons Court and West Kensington stations making us West London’s place to be for both locals and theatre-goers alike. The large windows flood the pub with natural light, plus our stylish interior and terrace tables make us the perfect pub to enjoy a Summer’s day. Our menu combines creative seasonal dishes with more traditional pub favourites; all made using the very best of British ingredients. On the bar you will find a good selection of perfectly kept cask ales, craft beers, delicious cocktails as well as our extensive wine list which will delight even the most discerning of palates.",
    walkTime: "8 mins",
    icon: featCurtainsIcon,
    hero: [featCurtainsHero, featCurtains1, featCurtains2],
    gallery: [featCurtainsHero, featCurtains1, featCurtains2],
  },
  barons: {
    title: "Baron’s Florist",
    desc: "Family business located in the heart of Kensington, specialising in everyday bouquets and bunches, wedding bouquets, flower arrangements for all occasion. Pop in and say hi!",
    hero: [featBaronsHero, featBarons2, featBarons3],
    icon: featBaronsIcon,
    gallery: [featBarons1, featBarons2, featBarons3],
    walkTime: "5 mins",
  },
  bonjour: {
    title: "Bonjour Brioche",
    desc: "Bonjour Brioche is a small Cafe in West Kensington that produces the finest quality cakes, breakfast, lunch and brunch. (ALL DAY BREAKFAST). We opened in September 2016 and since then we have been growing fast. New customers come everyday to taste our delicious menu.",
    icon: featBonjourIcon,
    hero: [featBonjourHero],
    gallery: [featBonjourHero],
    walkTime: "4 mins",
  },
  chapter: {
    title: "Chapter Coffee",
    desc: "Based in the heart of West Kensington, is taking artisan coffee roasting to the next level. We carefully source beans and work direct with trade farmers and co-operatives across the world, who share our passion. We roast by hand in small batches to ensure quality consistency and maximum flavour. In caring for each bean from farm to cup, we’ve seen our coffee win 3 Taste awards with a single origin taking the highest accolade of 3 stars.",
    hero: [featChapterHero, featChapter2, featChapter3],
    gallery: [featChapterHero, featChapter1, featChapter2, featChapter3],
    icon: featChapterIcon,
    walkTime: "4 mins",
  },
  bite: {
    title: "Bite",
    desc: "Here at Bite Café we can assure you that you would not be disappointed. Bite is a café located in Kensington Olympia serving fresh sandwiches, breads, cakes and artisan coffee.",
    hero: [featBiteHero, featBite2, featBite3],
    gallery: [featBiteHero, featBite2, featBite3],
    icon: featBiteIcon,
    walkTime: "4 mins",
  },
  underground: {
    title: "Coffee Underground",
    desc: "Neighbourhood coffee shop with good vibes and better coffee. From source to serve we look after every step of the journey.",
    hero: [
      featUndergroundHero,
      featUnderground1,
      featUnderground2,
      featUnderground3,
    ],
    gallery: [
      featUndergroundHero,
      featUnderground1,
      featUnderground2,
      featUnderground3,
    ],
    icon: featUndergroundIcon,
    walkTime: "4 mins",
  },
  tcr: {
    title: "TCR Barbers",
    desc: "A staple in West Kensington, Zee has run TCR Barbers over a decade.",
    hero: [featTcrHero, featTcr2, featTcr4],
    gallery: [featTcr1, featTcr2, featTcr3],
    icon: featTcrIcon,
    walkTime: "5 mins",
  },
  cumberland: {
    title: "The Cumberland Arms",
    desc: "Just around the corner from Olympia, this well-rated pub, delivers beautiful gastro style meals, quality fresh menu with a warm cozy atmosphere to enjoy.",
    hero: [featCumberlandHero, featCumberland1, featCumberland2],
    gallery: [featCumberland1, featCumberland2, featCumberland3],
    icon: featCumberlandIcon,
    walkTime: "8 mins",
  },
};
