import React, { Component } from "react";
import pannellum from 'pannellum';
import { G } from "../globals";

class OverlayVirtual extends Component {
	constructor(props) {
		super();

		this.pannellum = window.pannellum;
    // this.fakePannellum = pannellum;

		this.viewer = null;
		
	}

	componentDidMount() {
		this.Init();
	}

	Init() {

		this.viewer = this.pannellum.viewer('pano', {
			mouseZoom: false,
			keyboardZoom: false,
			showControls: false,
			scenes: {
				floor5th: {
					type: 'multires',
					multiRes: {
						basePath: './images/360/5thfloor',
						path: '/%l/%s%y_%x',
						fallBackPath: '/fallback/%s',
						extension: 'jpg',
						tileResolution: 512,
						maxLevel: 3,
						cubeResolution: 1904
					},
					hfov: 100,
					pitch: 0,
					yaw: 0
				},
				typical: {
					type: 'multires',
					multiRes: {
						basePath: './images/360/typical',
						path: '/%l/%s%y_%x',
						fallBackPath: '/fallback/%s',
						extension: 'jpg',
						tileResolution: 512,
						maxLevel: 3,
						cubeResolution: 1904
					},
					hfov: 100,
					pitch: 0,
					yaw: 0
				}
			}
		});


		
   
		if (this.props.content && typeof this.props.content === 'string') {
			console.log(this.props.content);
			this.viewer.loadScene(this.props.content);
			
		}

    
	}


	render() {
		return (
			<div className="overlay clickable">
				<div className="overlay-bg"></div>
				<div className="overlay-content">
					<div className="virtual">
						<div
							className="overlay-close"
							onClick={() => G.CloseOverlay('showVirtual')}
						></div>
						
				<div id="pano" className="virtual-container"></div>
					</div>
				</div>
			</div>
		);
	}
}

export default OverlayVirtual;
