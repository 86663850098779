import * as THREE from "three";
import { LoadingManager } from "three";
import { G } from "../globals";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import Entity from "./Entity";
import Loop from "./Loop";

class Ground extends Entity {
  constructor() {
    super();

    // let modelUrl = "models/map_test_v6a.glb";
    let modelUrl = "https://d3l9csg1k3rbp3.cloudfront.net/map_test_v6a.glb";
    // let modelUrl = "http://d3l9csg1k3rbp3.cloudfront.net/map_test_v5mob_ktx2.gltf";

    window.createImageBitmap = undefined;

    const IS_IOS =
      /^(iPad|iPhone|iPod)/.test(window.navigator.platform) ||
      (/^Mac/.test(window.navigator.platform) &&
        window.navigator.maxTouchPoints > 1);
    if (IS_IOS) {
      console.log(IS_IOS);
      modelUrl =
        "https://d3l9csg1k3rbp3.cloudfront.net/map_test_v5mob_ktx2.gltf";
      window.createImageBitmap = undefined;
    } else {
      console.log("NOT IOS");
    }

    // const imageTexLoader = new THREE.ImageBitmapLoader();
    // imageTexLoader.setOptions({
    //   imageOrientation: "flipY",
    //   resizeWidth: 512,
    //   resizeHeight: 512,
    // });

    this.SetBuildingState = this.SetBuildingState.bind(this);
    this.ResetBuildingState = this.ResetBuildingState.bind(this);

    this.obj = null;

    this.showingMap = true;

    const ktxLoader = new KTX2Loader();
    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    ktxLoader.setTranscoderPath("models/basis/");
    ktxLoader.detectSupport(G.renderer);
    dracoLoader.setDecoderPath("models/draco/");
    // loader.setPath("models/rz/");
    loader.setDRACOLoader(dracoLoader);
    loader.setKTX2Loader(ktxLoader);

    // const texLoader = new KTX2Loader();
    // const texLoadManager = new LoadingManager();
    // texLoadManager.addHandler(/\.ktx2$/, texLoader);
    // const loader = new GLTFLoader(texLoadManager);
    // const dracoLoader = new DRACOLoader();
    // dracoLoader.setDecoderPath("models/draco/");
    // loader.setKTX2Loader(texLoader);
    // loader.setDRACOLoader(dracoLoader);
    // texLoader.setTranscoderPath("models/basis");

    loader.load(modelUrl, (data) => {
      this.obj = data.scene;

      this.mapObjects = {
        TIERONE: this.obj.children.find((o) => o.name === "BUILDINGS_TIER1"),
        TIERTWO: this.obj.children.find((o) => o.name === "BUILDINGS_TIER2"),
        TIERTHREE: this.obj.children.find((o) => o.name === "BUILDINGS_TIER3"),
        TREES: this.obj.children.find((o) => o.name === "TREES"),
        // TRUNKS: this.obj.children.find((o) => o.name === "TREES-TRUNKS"),
        PARKS: this.obj.children.find((o) => o.name === "PARKS"),
        GROUND: this.obj.children.find((o) => o.name === "GROUND001"),
        ROADS: this.obj.children.find((o) => o.name === "ROADS"),
        WALKPATHS: this.obj.children.find((o) => o.name === "WalkPaths001"),
        DEVHIGHLIGHT_LO: this.obj.children.find(
          (o) => o.name === "DevelopmentHighlightLo"
        ),
        DEVHIGHLIGHT_HI: this.obj.children.find(
          (o) => o.name === "DevelopmentHighlightHi"
        ),
      };

      this.mapObjects.WALKPATHS.children.map((c) => {
        c.material.toneMapped = false;
        c.material.roughness = 1;
        c.material.emissive = new THREE.Color(c.material.color);
      });

      this.mapObjects.DEVHIGHLIGHT_LO.children.map((c) => {
        if (c.name === "Vert002") {
          c.material.transparent = true;
          c.material.toneMapped = false;
          c.material.opacity = 0.5;
          c.material.map.anisotropy = 1;
          c.material.map.magFilter = THREE.LinearFilter;
          c.material.map.minFilter = THREE.LinearMipmapLinearFilter;
        }
      });

      this.obj.traverse((o) => {
        if (o.material && o.material.map) {
          // o.material.map.anisotropy = 1;
          // o.material.map.magFilter = THREE.NearestFilter;
          // o.material.map.minFilter = THREE.NearestFilter;
          o.castShadow = false;
          o.material = new THREE.MeshBasicMaterial({
            color: new THREE.Color(`#ffffff`),
            name: o.material.name,
            toneMapped: false,
            map: o.material.map,
            reflectivity: 0,
            alphaTest: 0,
          });

          if (o.name === "WalkPaths001") {
            o.material.toneMapped = false;
          }

          if (o.material.name === "DevArea") {
            console.log(o);
            o.material.transparent = true;
            o.material.toneMapped = false;
            o.material.opacity = 0.5;
            o.material.map.anisotropy = 1;
            o.material.map.magFilter = THREE.LinearFilter;
            o.material.map.minFilter = THREE.LinearMipmapLinearFilter;
          }

          //bind AOs
          // if (o.name === "BUILDINGS_TIER1") {
          //   this.testObj = o;
          //   o.material.dMap = o.material.map;
          //   o.material.aMap = aoTextures.tierOne;
          // }

          o.material.map.anisotropy = 1;
          o.material.map.magFilter = THREE.LinearFilter;
          o.material.map.minFilter = THREE.LinearMipmapLinearFilter;
        }
      });

      this.obj.position.set(0, 0, 0);
      this.obj.scale.set(5, 5, 5);

      this.obj.castShadow = false;

      this.floors = {
        ROOF: this.obj.children.find((o) => o.name === "ROOF"),
        FIFTH: this.obj.children.find((o) => o.name === "FIFTH"),
        FOURTH: this.obj.children.find((o) => o.name === "FOURTH"),
        THIRD: this.obj.children.find((o) => o.name === "THIRD"),
        SECOND: this.obj.children.find((o) => o.name === "SECOND"),
        FIRST: this.obj.children.find((o) => o.name === "FIRST"),
        GROUND: this.obj.children.find((o) => o.name === "GROUND"),
        LG: this.obj.children.find((o) => o.name === "LG"),
      };

      this.floors.LG.visible = false;

      if (G.currentState && G.currentState.name === "CONNECTIONS") {
        this.mapObjects.DEVHIGHLIGHT_HI.visible = true;
        this.mapObjects.WALKPATHS.visible = true;
      } else if (G.currentState && G.currentState.name === "AMENITIES") {
        this.mapObjects.DEVHIGHLIGHT_HI.visible = true;
        this.mapObjects.WALKPATHS.visible = false;
      } else {
        this.mapObjects.DEVHIGHLIGHT_HI.visible = false;
        this.mapObjects.WALKPATHS.visible = false;
      }

      console.log(this.mapObjects);

      this.floorsArray = Object.keys(this.floors);

      this.Instantiate(this.obj, "env");

      if (G.currentState && G.currentState.name)
        this.SetBuildingState(G.currentState.name);
    });
  }

  SetBuildingState(_state) {
    if (!_state) return;
    this.ResetBuildingState();
    switch (_state) {
      case "FIFTH":
        this.floors.ROOF.visible = false;
        this.floors.LG.visible = false;
        break;
      case "FOURTH":
        this.floors.ROOF.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.LG.visible = false;
        break;
      case "THIRD":
        this.floors.ROOF.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.LG.visible = false;
        break;
      case "SECOND":
        this.floors.ROOF.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        this.floors.LG.visible = false;
        break;
      case "FIRST":
        this.floors.ROOF.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        this.floors.SECOND.visible = false;
        this.floors.LG.visible = false;
        break;
      case "GROUND":
        this.floors.ROOF.visible = false;
        this.floors.FIFTH.visible = false;
        this.floors.FOURTH.visible = false;
        this.floors.THIRD.visible = false;
        this.floors.SECOND.visible = false;
        this.floors.FIRST.visible = false;
        this.floors.LG.visible = false;
        break;
      case "LOWERGROUND":
        this.HideMapItems();
        this.floors.LG.visible = true;
        this.floors.ROOF.visible = true;
        this.floors.FIFTH.visible = true;
        this.floors.FOURTH.visible = true;
        this.floors.THIRD.visible = true;
        this.floors.SECOND.visible = true;
        this.floors.FIRST.visible = true;
        this.floors.GROUND.visible = true;
        break;
      default:
        break;
    }
  }

  HideMapItems() {
    if (!this.showingMap) return;
    this.showingMap = false;
    Object.values(this.mapObjects).map((m) => (m.visible = false));
  }

  ShowMapItems() {
    if (this.showingMap) return;
    this.showingMap = true;
    Object.values(this.mapObjects).map((m) => {
      if (m) m.visible = true;
    });
  }

  ResetBuildingState() {
    if (!this.floors) return;
    this.ShowMapItems();
    this.floorsArray.map((fl) => {
      if (!this.floors[fl]) return;
      this.floors[fl].visible = true;
      return null;
    });
    this.floors.LG.visible = false;
  }

  onStateChange(newState) {
    if (this.floors) this.SetBuildingState(newState.name);
    if (this.mapObjects && newState.name === "CONNECTIONS") {
      this.mapObjects.DEVHIGHLIGHT_HI.visible = true;
      this.mapObjects.WALKPATHS.visible = true;
    } else if (this.mapObjects && newState.name === "AMENITIES") {
      this.mapObjects.DEVHIGHLIGHT_HI.visible = true;
    } else if (this.mapObjects) {
      this.mapObjects.WALKPATHS.visible = false;
      this.mapObjects.DEVHIGHLIGHT_HI.visible = false;
    }
  }
}

export default Ground;
