import U from "../utils";
import { G } from "../globals";
import Overview from "../components/overview";
import Building from "../components/building";
import Amenities from "../components/amenities";
import Connections from "../components/connections";
import Floors from "../components/floors";
import Intro from "../components/intro";
import Contact from "../components/contact";
import AmenityInfo from "../components/amenityInfo";

export const STATE = {
  INIT: {
    ui: null,
    camData: {
      camera: {
        pos: { x: -162.119, y: 76.05, z: 83.143 },
        duration: 200,
        fov: 30,
      },
      target: {
        pos: { x: -123.287, y: 0, z: -84.304 },
        duration: 200,
      },
      controls: [],
    },
  },
  INTRO: {
    ui: Intro,
    camData: {
      camera: {
        pos: { x: -482.583, y: 187.559, z: -493.049 },
        duration: 1000,
        fov: 30,
      },
      target: {
        pos: { x: 22.547, y: 6.9, z: -403.776 },
        duration: 1000,
      },
      controls: [],
    },
  },
  CONTACT: {
    ui: Contact,
    camData: {
      camera: {
        pos: { x: -482.583, y: 187.559, z: -493.049 },
        duration: 1000,
        fov: 30,
      },
      target: {
        pos: { x: 22.547, y: 6.9, z: -403.776 },
        duration: 1000,
      },
      controls: [],
    },
  },
  DEBUG: {
    ui: null,
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -1118.568, y: 787.181, z: -1077.062 },
        duration: 3000,
        fov: 20,
      },
      target: {
        pos: { x: -158.601, y: 6.9, z: -490.407 },
        duration: 3000,
      },
      controls: ["all"],
    },
    markers: ["foldMarker", "overviewMarkers", "featureAmenityMarkers"],
  },
  OVERVIEW: {
    ui: Overview,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -823.751, y: 513.925, z: -764.456 }, //{ x: -904.868, y: 581.268, z: -787.285 },
        duration: 3000,
        fov: 25,
        filmOffset: -6,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          hOffset: {
            tablet: 200,
            mobile: 125,
          },
        },
      },
      target: {
        pos: { x: 11.601, y: 6.9, z: -355.228 }, //{ x: 23.274, y: 6.9, z: -355.297 },
        duration: 3000,
      },
      controls: ["orbit", "gyro"],
    },
    markers: ["foldMarker", "overviewMarkers"],
  },
  BUILDING: {
    ui: Building,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -431.706, y: 198.687, z: -645.765 },
        duration: 3000,
        fov: 40,
        filmOffset: -3,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: 121.79, y: 6.9, z: -325.333 },
        duration: 3000,
      },
      controls: ["gyro"],
    },
    markers: [
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  AMENITIES: {
    ui: Amenities,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -2856.134, y: 3662.693, z: 1858.079 },
        duration: 3000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          hOffset: {
            tablet: 200,
            mobile: 125,
          },
        },
      },
      target: {
        pos: { x: -1433.011, y: 6.9, z: 271.175 },
        duration: 3000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  CONNECTIONS: {
    ui: Connections,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -1060.241, y: 7399.845, z: -346.411 },
        duration: 3000,
        fov: 20,
        filmOffset: -6,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          hOffset: {
            tablet: 200,
            mobile: 125,
          },
        },
      },
      target: {
        pos: { x: -323.441, y: 6.9, z: -738.724 },
        duration: 3000,
      },
      controls: ["pan", "gyro"],
    },
    markers: ["foldMarker", "transpStations", "connectionOlympiaMarker"],
  },
  FIFTH: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -205.131, y: 428.681, z: -562.272 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: 35.992, y: 121.5, z: -367.553 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      "floorMarkersFifth",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  FOURTH: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -205.131, y: 403.681, z: -562.272 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: 35.992, y: 96.2, z: -367.553 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      "floorMarkersFourth",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  THIRD: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -205.131, y: 384.681, z: -562.272 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: 35.992, y: 77.2, z: -367.553 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      "floorMarkersThird",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  SECOND: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -205.131, y: 365.681, z: -562.272 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: 35.992, y: 58.2, z: -367.553 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      "floorMarkersSecond",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  FIRST: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -205.131, y: 348.681, z: -562.272 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: 35.992, y: 41.2, z: -367.553 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      "floorMarkersFirst",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  GROUND: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -205.131, y: 323.681, z: -562.272 },
        duration: 3000,
        fov: 40,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: 35.992, y: 16.2, z: -367.553 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  LOWERGROUND: {
    ui: Floors,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -384.806, y: 392.156, z: -337.574 },
        duration: 3000,
        fov: 30,
        portraitMode: {
          filmOffset: 0,
          fov: 50,
          // hOffset: {
          // 	tablet: 200,
          // 	mobile: 125,
          // },
        },
        // filmOffset: -6,
      },
      target: {
        pos: { x: -166.203, y: 6.9, z: -340.078 },
        duration: 3000,
      },
      controls: ["pan", "orbit", "zoom", "gyro"],
    },
    markers: [
      "floorMarkersLG",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },

  CURTAINS: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -3193.997, y: 2096.364, z: 1627.616 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -1499.265, y: 6.9, z: 404.717 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },

  OLYMPIA: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -4398.295, y: 1848.904, z: -1414.967 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -2642.283, y: 6.9, z: -2667.928 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },

  CHAPTER: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -2382.107, y: 1848.904, z: -288.34 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -649.089, y: 6.9, z: -441.852 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },

  BARONS: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -2285.324, y: 1596.072, z: 1527.569 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -827.434, y: 6.9, z: 197.537 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  BONJOUR: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -1940.108, y: 1610.154, z: 1813.46 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -749.105, y: 6.9, z: 254.299 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  UNDERGROUND: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -2433.789, y: 1581.91, z: 1190.839 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -832.66, y: 6.9, z: 17.967 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  BITE: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -3368.607, y: 1313.906, z: -1686.479 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -1328.227, y: 6.9, z: -945.886 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  TCR: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -2473.519, y: 1420.846, z: 1516.465 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -970.479, y: 6.9, z: 277.278 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
  CUMBERLAND: {
    ui: AmenityInfo,
    options: {
      showCompass: true,
      showInstructions: true,
    },
    camData: {
      interactable: true,
      panSpeed: 4,
      camera: {
        pos: { x: -3707.803, y: 1638.595, z: -2289.225 },
        duration: 2000,
        fov: 20,
        filmOffset: -16,
        portraitMode: {
          filmOffset: 0,
          fov: 30,
          hOffset: {
            tablet: 200,
            mobile: 70,
          },
        },
      },
      target: {
        pos: { x: -1487.404, y: 6.9, z: -1221.115 },
        duration: 2000,
      },
      controls: ["pan", "gyro"],
    },
    markers: [
      "foldMarker",
      "featureAmenityMarkers",
      // 'featureMarkersHome',
      // 'galleryMarkers'
    ],
  },
};

export function GetStateArray() {
  // console.log(STATE);
  return Object.values(STATE);
}

//assign names and indices to state
const stateKeys = Object.keys(STATE);
stateKeys.map((k, i) => {
  STATE[k].name = k;

  return null;
});
