import React, { Component } from "react";
import VimeoPlayer from "@vimeo/player";

class TopNav extends Component {
  constructor() {
    super();
    
    this.state = {
      isModalOpen: false, // Modal state
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  componentDidMount() {
    // Add event listeners for modal close when clicking outside the modal
    window.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleOutsideClick);
  }

  openModal() {
    // Show the modal
    this.setState({ isModalOpen: true }, () => {
      this.modal.style.display = "flex";

      // Initialize Vimeo player and play the video
      this.player = new VimeoPlayer(this.vimeoIframe);
      this.player.play();
    });
  }

  closeModal() {
    // Hide the modal and pause the video
    this.setState({ isModalOpen: false }, () => {
      this.modal.style.display = "none";
      if (this.player) {
        this.player.pause();
      }
    });
  }

  handleOutsideClick(event) {
    if (event.target === this.modal) {
      this.closeModal();
    }
  }

  setNav(name) {
    if (!this.props.currentState.name) return "";
    if (this.props.currentState.name.toLowerCase() === name.toLowerCase())
      return "top-nav-active";
    else return "";
  }

  render() {
    return (
      <div>
        <div
          className={`top-nav  ${
            this.props.currentState.name === "INTRO" ? "hidden" : ""
          }`}
        >
          <ul className="top-nav-list w-list-unstyled clickable">
            <li
              className={`top-nav-item vid-trigger`}
              onClick={this.openModal} // Open modal on click
            >
              Play Completion Video
            </li>
            <li
              className={`top-nav-item ${this.setNav("OVERVIEW")}`}
              onClick={() => this.props.GoToState("OVERVIEW", true)}
            >
              Overview
            </li>
            <li
              className={`top-nav-item ${this.setNav("BUILDING")}`}
              onClick={() => this.props.GoToState("BUILDING", true)}
            >
              Building
            </li>
            <li
              className={`top-nav-item ${this.setNav("AMENITIES")}`}
              onClick={() => this.props.GoToState("AMENITIES", true)}
            >
              Amenities
            </li>
            <li
              className={`top-nav-item ${this.setNav("CONNECTIONS")}`}
              onClick={() => this.props.GoToState("CONNECTIONS", true)}
            >
              Connections
            </li>
            <li
              className={`top-nav-item ${this.setNav("CONTACT")}`}
              onClick={() => this.props.GoToState("CONTACT", true)}
            >
              Contact
            </li>
          </ul>
        </div>

        {/* Modal Structure */}
        <div
          id="myModal"
          ref={(ref) => (this.modal = ref)}
          className="video-modal"
          style={{ display: this.state.isModalOpen ? "flex" : "none" }}
        >
         
          <div className="modal-content">
          <span className="close" onClick={this.closeModal}>
            &times;
          </span>
            {/* Vimeo Video */}
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                ref={(ref) => (this.vimeoIframe = ref)}
                src="https://player.vimeo.com/video/1003538152?h=2f3c75db5f&badge=0&autopause=0&player_id=0&app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="The Fold Viewcast"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopNav;