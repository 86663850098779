import React, { Component } from "react";
import { G } from "../globals";

class OverlayGallery extends Component {
	constructor(props) {
		super();

		this.gallery = {
			images: props.content,
			isSingle: !Array.isArray(props.content) || props.content.length <= 1,
			galleryImageContainer: null,
			thumbWrappper: null,
			currentSlide: 0,
		};
	}

	componentDidMount() {
		this.gallery.galleryImageContainer = this.galleryImg;
		this.gallery.thumbWrappper = this.thumbDiv;
		this.jumpToSlide(0);
	}

	jumpToSlide(index) {
		this.galleryImg.classList.add("fade-out");

		setTimeout(() => {
			this.gallery.galleryImageContainer.style.backgroundImage = `url(${this.gallery.images[index].img})`;
			if (this.props.isContain)
				this.gallery.galleryImageContainer.style.backgroundSize = "contain";
			this.galleryLabel.innerHTML = this.gallery.images[index].caption;
			setTimeout(() => {
				this.galleryImg.classList.remove("fade-out");
			}, 100);

			const thumbs = Array.from(this.gallery.thumbWrappper.children);
			thumbs.map((thumb) => thumb.classList.remove("thumb-active"));
			thumbs[index].classList.add("thumb-active");
		}, 400);
	}

	navigateSlides(_dir) {
		let dir = 0;

		if (_dir === "next") {
			dir = +1;
		} else if (_dir === "prev") {
			dir = -1;
		}
		this.gallery.currentSlide += dir;

		if (this.gallery.currentSlide > this.gallery.images.length - 1) {
			this.gallery.currentSlide = 0;
		} else if (this.gallery.currentSlide < 0) {
			this.gallery.currentSlide = this.gallery.images.length - 1;
		}

		this.jumpToSlide(this.gallery.currentSlide);
	}

	render() {
		return (
			<div className="overlay clickable">
				<div className="overlay-bg"></div>
				<div className="overlay-content">
					<div className="gallery">
						<div
							className="overlay-close"
							onClick={() => G.CloseOverlay("showGallery")}
						></div>
						<div ref={(ref) => (this.galleryImg = ref)} className="gallery-image"></div>
						<div className="gallery-controls">
							<div
								ref={(ref) => (this.galleryLabel = ref)}
								className="gallery-label"
							></div>
							<div className="gallery-controls-container">
								<div
									ref={(ref) => (this.thumbDiv = ref)}
									className="gallery-thumb-container"
								>
									{this.gallery.images.map((img, i) => {
										return (
											<div
												key={i}
												className="thumb dark-thumb"
												onClick={() => this.jumpToSlide(i)}
											></div>
										);
									})}
								</div>
								<div
									className="gallery-button prev-button"
									onClick={() => this.navigateSlides("prev")}
								></div>
								<div
									className="gallery-button next-button"
									onClick={() => this.navigateSlides("next")}
								></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OverlayGallery;
