import React, { Component } from "react";
import { G } from "../globals";
import { amenityData } from "../data/amenityData";

class AmenityInfo extends Component {
	constructor(props) {
		super();

		this.content = amenityData[props.currentState.name.toLowerCase()];
		this.currentSlide = 0;
	}

	componentDidMount() {
		this.jumpToSlide(0);
		G.activateAmenityMarker(this.props.currentState.name.toLowerCase());
	}

	jumpToSlide(index) {
		this.galleryImage.classList.add("fade");
		setTimeout(() => {
			this.galleryImage.style.backgroundImage = `url(${this.content.gallery[index]})`;
			this.galleryImage.classList.remove("fade");
		}, 400);
	}

	navigateSlides(_dir) {
		let dir = 0;

		if (_dir === "next") {
			dir = +1;
		} else if (_dir === "prev") {
			dir = -1;
		}
		this.currentSlide += dir;

		if (this.currentSlide > this.content.gallery.length - 1) {
			this.currentSlide = 0;
		} else if (this.currentSlide < 0) {
			this.currentSlide = this.content.gallery.length - 1;
		}

		this.jumpToSlide(this.currentSlide);
	}

	render() {
		return (
			<div className="slide">
				<div className="slide-content amenity-info-content">
					<div className="slide-render-reserve amenity-reserve"></div>
					<div className="slide-panel amenity-info-panel clickable">
						<div className="amenity-info-gallery">
							<div className="amenity-info-gallery-controls">
								<div
									className="amenity-gallery-button prev-button"
									onClick={() => this.navigateSlides("prev")}
								></div>
								<div
									className="amenity-gallery-button next-button"
									onClick={() => this.navigateSlides("next")}
								></div>
							</div>
							<div
								className="return-button return-button-white"
								onClick={() => {
									this.props.GoToState("AMENITIES");
								}}
							>
								Return
							</div>
							<div
								ref={(ref) => (this.galleryImage = ref)}
								className="amenity-gallery-image"
							></div>
						</div>
						<div className="amenity-info-content">
							<div className="amenity-walk-times">{this.content.walkTime}</div>
							<h3 className="amenity-info-title">{this.content.title}</h3>
							<div className="amenity-info-copy">{this.content.desc}</div>
							<div
								className="amenity-info-gfx"
								style={{
									backgroundImage: `url(${this.content.icon})`,
									backgroundSize: "contain",
								}}
							></div>
						</div>
					</div>
					<div className="slide-sub-panel"></div>
				</div>
			</div>
		);
	}
}

export default AmenityInfo;
