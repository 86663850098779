import React, { Component } from "react";
import Instructions from "./instructions";
import { G } from "../globals";

class Nav extends Component {
  constructor() {
    super();

    this.isBurgerOpen = false;
    this.isBurgerProgress = false;
  }

  toggleBurger() {
    if (this.isBurgerProgress) return;
    if (this.isBurgerOpen) this.closeBurger();
    else this.openBurger();
  }

  openBurger() {
    if (this.isBurgerOpen) return;
    const burgerCols = [
      ...this.burgerContent.querySelectorAll(".burger-col-item"),
    ];

    this.isBurgerProgress = true;

    this.burgerContent.classList.remove("hidden");

    this.burgerButton.classList.add("nav-burger-close");

    setTimeout(() => {
      this.burgerBg.classList.add("burger-bg-open");
    }, 10);

    setTimeout(() => {
      burgerCols.map((b, i) => {
        setTimeout(() => {
          b.classList.add("burger-bg-open");
        }, i * 200);
      });
      this.isBurgerOpen = true;
      this.isBurgerProgress = false;
    }, 400);
  }

  closeBurger() {
    if (!this.isBurgerOpen) return;
    const burgerCols = [
      ...this.burgerContent.querySelectorAll(".burger-col-item"),
    ];

    this.isBurgerProgress = true;

    this.burgerButton.classList.remove("nav-burger-close");
    burgerCols.map((b) => b.classList.remove("burger-bg-open"));

    setTimeout(() => {
      this.burgerBg.classList.remove("burger-bg-open");

      setTimeout(() => {
        this.burgerContent.classList.add("hidden");
        this.isBurgerOpen = false;
        this.isBurgerProgress = false;
      }, 1000);
    }, 100);
  }

  goToStateHandler(state) {
    this.closeBurger();
    this.props.GoToState(state);
  }

  goToOverlayHandler(overlay, data) {
    this.closeBurger();
    G.OpenOverlay(overlay, data);
  }

  render() {
    return (
      <div
        className={`nav ${
          this.props.currentState.name === "INTRO" ? "hidden" : ""
        }`}
      >
        <div
          ref={(ref) => (this.burgerContent = ref)}
          className="burger-content hidden clickable"
        >
          <div ref={(ref) => (this.burgerBg = ref)} className="burger-bg"></div>
          <ul className="burger-col-list w-list-unstyled">
            <li className="burger-col-item">
              <ul className="burger-list w-list-unstyled">
                <li
                  className="burger-item"
                  onClick={() => this.goToStateHandler("OVERVIEW")}
                >
                  Overview
                </li>
                <li
                  className="burger-item"
                  onClick={() => this.goToOverlayHandler("showSust", true)}
                >
                  Sustainability
                </li>
                <li
                  className="burger-item"
                  onClick={() => this.goToOverlayHandler("showWellness", true)}
                >
                  Wellness
                </li>
              </ul>
            </li>
            <li className="burger-col-item">
              <ul className="burger-list w-list-unstyled">
                <li
                  className="burger-item"
                  onClick={() => this.goToStateHandler("BUILDING")}
                >
                  Building
                </li>
                <li
                  className="burger-item"
                  onClick={() =>
                    this.goToOverlayHandler("showGallery", [
                      {
                        img: "./images/gallery/comp/overview-1.jpg",
                        caption: "",
                      },
                      {
                        img: "./images/gallery/comp/overview-2.jpg",
                        caption: "Entrance",
                      },
                      {
                        img: "./images/gallery/comp/overview-3.jpg",
                        caption: "Entrance",
                      },
                      {
                        img: "./images/gallery/comp/ground-1.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-2.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-3.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-4.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-5.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/terrace-1.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-2.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-3.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-4.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-5.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/typical-1.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-2.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-3.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-4.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-5.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-6.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-7.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/wc-1.jpg",
                        caption: "WC",
                      },
                      {
                        img: "./images/gallery/comp/lower-ground-1.jpg",
                        caption: "Lower Ground - Bike Storage",
                      },
                      {
                        img: "./images/gallery/comp/lower-ground-2.jpg",
                        caption: "Lower Ground",
                      },
                      {
                        img: "./images/gallery/comp/lower-ground-3.jpg",
                        caption: "Lower Ground - Changing Rooms",
                      },
                    ])
                  }
                >
                  Gallery
                </li>
                <li
                  className="burger-item"
                  onClick={() => this.goToOverlayHandler("showSpec", true)}
                >
                  Specification
                </li>
                <li
                  className="burger-item"
                  onClick={() => this.goToStateHandler("FIFTH")}
                >
                  Floors
                </li>
              </ul>
            </li>
            <li className="burger-col-item">
              <ul className="burger-list w-list-unstyled">
                <li
                  className="burger-item"
                  onClick={() => this.goToStateHandler("AMENITIES")}
                >
                  Amenities
                </li>
                <li className="burger-item-divider"></li>
                <li
                  className="burger-item"
                  onClick={() => this.goToStateHandler("CONNECTIONS")}
                >
                  Connections
                </li>
              </ul>
            </li>
            <li className="burger-col-item">
              <ul className="burger-list w-list-unstyled">
                <li
                  className="burger-item"
                  onClick={() => this.goToStateHandler("CONTACT")}
                >
                  Contact &amp;
                  <br />
                  Downloads
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="nav-content clickable">
          <div className="nav-section-title">
            {this.props.currentState.name
              ? this.props.currentState.name
              : "OVERVIEW"}
          </div>
          <ul className="instructions w-list-unstyled">
            <Instructions currentState={this.props.currentState} />
            {/* <li className="instruction">Touch and drag to look around</li>
            <li className="instruction-divider">//</li>
            <li className="instruction">Touch and drag to look around</li>
            <li className="instruction-divider">//</li>
            <li className="instruction">Touch and drag to look around</li>
            <li className="instruction-divider">//</li>
						<li className="instruction-reset">Reset</li>*/}
          </ul>
          <div
            ref={(ref) => (this.burgerButton = ref)}
            onClick={() => this.toggleBurger()}
            className="nav-burger"
          ></div>
        </div>
      </div>
    );
  }
}

export default Nav;
