import React, { Component } from "react";

class Instruction extends Component {
  render() {
    return (
      <li
        key={this.props.key}
        className={`instruction ${this.props.icon} ${
          this.props.show ? "" : "hidden"
        }`}
      >
        {this.props.children}
      </li>
    );
  }
}

export default Instruction;
