import React, { Component } from "react";
import { G } from "../globals";

class OverlaySustainability extends Component {
	render() {
		return (
			<div className="overlay clickable">
				<div className="overlay-bg"></div>
				<div className="overlay-content">
					<div className="overlay-col-left">
						<div className="return-button" onClick={() => G.CloseOverlay("showSust")}>
							Return
						</div>
						<div className="overlay-sub-title">Sustainability</div>
						<div className="overlay-copy">
							The Fold has been designed with a range of measures to improve
							sustainability and reduce energy consumption.
						</div>
					</div>
					<div className="overlay-col-right">
						<ul className="overlay-featuresust-list w-list-unstyled">
							<li className="overlay-featuresust-item">
								<div className="featurespec-icon carbon-spec"></div>
								<div className="featurespec-label">
									carbon dioxide emissions reduction
								</div>
							</li>
							<li className="overlay-featuresust-item">
								<div className="featurespec-icon breeam-spec"></div>
								<div className="featurespec-label">BREEAM ‘Excellent’</div>
							</li>
							<li className="overlay-featuresust-item">
								<div className="featurespec-icon solar-spec"></div>
								<div className="featurespec-label">
									Rooftop solar panel to supplement electrical demand
								</div>
							</li>
							<li className="overlay-featuresust-item">
								<div className="featurespec-icon spec-electric"></div>
								<div className="featurespec-label">Fully Electric building</div>
							</li>
						</ul>

						<ul className="spec-list w-list-unstyled">
							<li className="spec-item">
								Reduction in car parking spaces and provision of secure end of trip
								facilities to promote active travel
							</li>
							<li className="spec-item">Green roofs to promote biodiversity</li>
							<li className="spec-item">Targeting NZC operational</li>
							<li className="spec-item">Dual feed power</li>
							<li className="spec-item">Materials from sustainable resources</li>
							<li className="spec-item">BREEAM rating of Excellent</li>
							<li className="spec-item">WELL rating of Gold</li>
							<li className="spec-item">High efficiency Air Source Heat Pumps</li>
							<li className="spec-item">
								High efficiency fans incorporating heat recovery
							</li>
							<li className="spec-item">High efficiency LED luminaries</li>
							<li className="spec-item">
								Lighting will incorporate occupancy and daylight linked controls to
								reduce demand
							</li>
							<li className="spec-item">
								A PV (solar panel) array is to be situated on the roof to supplement
								the building’s electrical demand
							</li>
							<li className="spec-item">EPC rating of A</li>
							<li className="spec-item">Entirely serviced by electricity</li>
							<li className="spec-item">
								Careful material selection, avoiding the use of materials with high
								embodied energy such as concrete
							</li>
							<li className="spec-item">
								Passive solar shading at roof level to prevent overheating
							</li>
							<li className="spec-item">
								Blinds throughout for additional solar shading and occupier comfort
							</li>
							<li className="spec-item">
								Where possible, existing raised access floors, ceilings and
								partitions will be retained and refurbished to reduced the amount of
								waste sent to landfill as a result of the refurbishment
							</li>
							<li className="spec-item">Wiredscore Gold Certification</li>
						</ul>
					</div>
					<div className="overlay-close" onClick={() => G.CloseOverlay("showSust")}></div>
				</div>
			</div>
		);
	}
}

export default OverlaySustainability;
