import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader";
import Entity from "./Entity";
import { G } from "../globals";
import U from "../utils";
import Loop from "./Loop";

class Lights extends Entity {
  constructor() {
    super();

    const ambLight = new THREE.AmbientLight("#ffffff", 0.2);
    ambLight.castShadow = false;

    this.Instantiate(ambLight, "ambLight");

    const pmremGenerator = new THREE.PMREMGenerator(G.renderer);
    pmremGenerator.compileEquirectangularShader();

    new EXRLoader()
      .setDataType(THREE.HalfFloatType)
      .load("models/schadowplatz_1k.exr", (texture) => {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;
        G.scene.environment = envMap;
        texture.dispose();
        pmremGenerator.dispose();
      });

    // new RGBELoader()
    // 	.setDataType(THREE.UnsignedByteType)
    // 	.setPath("models/")
    // 	.load("schadowplatz_1k.hdr", function (texture) {
    // 		const envMap = pmremGenerator.fromEquirectangular(texture).texture;

    // 		// G.scene.background = envMap;
    // 		G.scene.environment = envMap;

    // 		texture.dispose();
    // 		pmremGenerator.dispose();
    // 	});
  }
}

export default Lights;
