import React, { Component } from "react";
import { G } from "../globals";

class Floors extends Component {
  constructor() {
    super();

    this.getFloorData = this.getFloorData.bind(this);
  }

  setNav(name) {
    if (this.props.currentState.name.toLowerCase() === name.toLowerCase())
      return "floor-selector-item-active";
    else return "";
  }

  getFloorData(floor) {
    let result = { title: "UNDEFINED", sqft: "XXXX", plan: "" };
    switch (floor) {
      case "FIFTH":
        result = {
          title: "FIFTH",
          sqft: "5,480",
          plan: "./images/plans/plan5.png",
          gallery: [
            {
              img: "./images/gallery/comp/floor-5.jpg",
              caption: "5th floor",
            },
            {
              img: "./images/gallery/comp/floor-5-2.jpg",
              caption: "5th floor",
            },
            // {
            //   img: "./images/gallery/img9.jpg",
            //   caption: "5th Floor Private Terrace",
            // },
            // {
            //   img: "./images/gallery/img5.jpg",
            //   caption: "5th Floor Private Terrace",
            // },
            {
              img: "./images/gallery/comp/terrace-1.jpg",
              caption: "Communal 5th floor terrace",
            },
            {
              img: "./images/gallery/comp/terrace-2.jpg",
              caption: "Communal 5th floor terrace",
            },
            {
              img: "./images/gallery/comp/terrace-3.jpg",
              caption: "Communal 5th floor terrace",
            },
            {
              img: "./images/gallery/comp/terrace-4.jpg",
              caption: "Communal 5th floor terrace",
            },
            {
              img: "./images/gallery/comp/terrace-5.jpg",
              caption: "Communal 5th floor terrace",
            },
          ],
        };
        break;
      case "FOURTH":
        result = {
          title: "FOURTH",
          sqft: "17,073",
          plan: "./images/plans/plan14.png",
          gallery: [
            {
              img: "./images/gallery/comp/floor-4.jpg",
              caption: "4th Floor",
            },
            {
              img: "./images/gallery/comp/floor-4-2.jpg",
              caption: "4th Floor",
            },
            {
              img: "./images/gallery/comp/typical-1.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-6.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-4.jpg",
              caption: "Typical Floor",
            },
          ],
        };
        break;
      case "THIRD":
        result = {
          title: "THIRD",
          sqft: "9,070",
          plan: "./images/plans/plan3.png",
          gallery: [
            {
              img: "./images/gallery/comp/floor-3.jpg",
              caption: "3rd Floor",
            },
            {
              img: "./images/gallery/comp/typical-1.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-2.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-3.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-6.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-4.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-7.jpg",
              caption: "Typical Floor",
            },
          ],
        };
        break;
      case "SECOND":
        result = {
          title: "SECOND",
          sqft: "16,920",
          plan: "./images/plans/plan14.png",
          gallery: [
            {
              img: "./images/gallery/comp/floor-2.jpg",
              caption: "2nd Floor",
            },
            {
              img: "./images/gallery/comp/typical-1.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-2.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-3.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-6.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-4.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-7.jpg",
              caption: "Typical Floor",
            },
          ],
        };
        break;
      case "FIRST":
        result = {
          title: "FIRST",
          sqft: "16,888",
          plan: "./images/plans/plan14.png",
          gallery: [
            {
              img: "./images/gallery/comp/floor-1.jpg",
              caption: "1st Floor",
            },
            {
              img: "./images/gallery/comp/floor-1-2.jpg",
              caption: "1st Floor",
            },
            {
              img: "./images/gallery/comp/typical-1.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-2.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-3.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-6.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-4.jpg",
              caption: "Typical Floor",
            },
            {
              img: "./images/gallery/comp/typical-7.jpg",
              caption: "Typical Floor",
            },
          ],
        };
        break;
      case "GROUND":
        result = {
          title: "GROUND",
          sqft: "13,750",
          plan: "./images/plans/planG.png",
          gallery: [
            {
              img: "./images/gallery/comp/ground-1.jpg",
              caption: "Reception",
            },
            {
              img: "./images/gallery/comp/ground-2.jpg",
              caption: "Reception",
            },
            {
              img: "./images/gallery/comp/ground-3.jpg",
              caption: "Reception",
            },
            {
              img: "./images/gallery/comp/ground-5.jpg",
              caption: "Reception",
            },
            {
              img: "./images/gallery/comp/floor-0.jpg",
              caption: "Ground Floor",
            },
          ],
        };
        break;
      case "LOWERGROUND":
        result = {
          title: "LOWER GROUND",
          sqft: "",
          plan: "./images/plans/planLG.png",
          gallery: [
            {
              img: "./images/gallery/comp/lower-ground-1.jpg",
              caption: "Lower Ground - Bike Storage",
            },
            {
              img: "./images/gallery/comp/lower-ground-2.jpg",
              caption: "Lower Ground",
            },
            {
              img: "./images/gallery/comp/lower-ground-3.jpg",
              caption: "Lower Ground - Changing Rooms",
            },
            {
              img: "./images/gallery/comp/lower-ground-4.jpg",
              caption: "Lower Ground - Changing Rooms",
            },
          ],
        };
        break;
      default:
        result = { title: "UNDEFINED", sqft: "XXXX", plan: "" };
        break;
    }

    return result;
  }

  render() {
    return (
      <div className="slide">
        <div className="slide-content floors-content">
          <div className="slide-floors-group">
            <div className="slide-panel floors-panel clickable">
              <div
                className="return-button"
                onClick={() => this.props.GoToState("BUILDING")}
              >
                Return
              </div>
              <h2 className="floor-title">
                {`${
                  this.props.currentState.name === "LOWERGROUND"
                    ? "LOWER GROUND"
                    : this.props.currentState.name
                }`}
                <br />
                <span className="fold-outline">
                  {this.getFloorData(this.props.currentState.name).sqft}
                </span>
              </h2>
              <ul className="slide-nav w-list-unstyled">
                <li
                  className="slide-nav-item"
                  onClick={() =>
                    G.OpenOverlay(
                      "showGallery",
                      this.getFloorData(this.props.currentState.name).gallery
                    )
                  }
                >
                  Gallery
                </li>
                <li className="slide-div-item"></li>
                <li
                  className="slide-nav-item"
                  onClick={() =>
                    G.OpenOverlay(
                      "showPlan",
                      this.getFloorData(this.props.currentState.name)
                    )
                  }
                >
                  Floor plan
                </li>
              </ul>
            </div>
            <div className="slide-sub-panel floor-sub clickable">
              <ul className="floor-selector-list w-list-unstyled">
                <li
                  className={`floor-selector-item ${this.setNav("FIFTH")}`}
                  onClick={() => this.props.GoToState("FIFTH")}
                  style={{ flexWrap: "wrap" }}
                >
                  <div className="floor-selector-label">Fifth</div>
                  <div className="floor-selector-value">
                    {this.getFloorData("FIFTH").sqft}
                  </div>
                  <div className="floor-selector-label">Communal Terrace</div>
                  <div className="floor-selector-value">3,260</div>
                </li>

                <li
                  className={`floor-selector-item ${this.setNav("FOURTH")}`}
                  onClick={() => this.props.GoToState("FOURTH")}
                >
                  <div className="floor-selector-label">Fourth</div>
                  <div className="floor-selector-value">
                    {this.getFloorData("FOURTH").sqft}
                  </div>
                </li>
                <li
                  className={`floor-selector-item ${this.setNav("THIRD")}`}
                  onClick={() => this.props.GoToState("THIRD")}
                >
                  <div className="floor-selector-label">Third</div>
                  <div className="floor-selector-value">
                    {this.getFloorData("THIRD").sqft}
                  </div>
                </li>
                <li
                  className={`floor-selector-item ${this.setNav("SECOND")}`}
                  onClick={() => this.props.GoToState("SECOND")}
                >
                  <div className="floor-selector-label">Second</div>
                  <div className="floor-selector-value">
                    {this.getFloorData("SECOND").sqft}
                  </div>
                </li>
                <li
                  className={`floor-selector-item ${this.setNav("FIRST")}`}
                  onClick={() => this.props.GoToState("FIRST")}
                >
                  <div className="floor-selector-label">First</div>
                  <div className="floor-selector-value">
                    {this.getFloorData("FIRST").sqft}
                  </div>
                </li>
                <li
                  className={`floor-selector-item ${this.setNav("GROUND")}`}
                  onClick={() => this.props.GoToState("GROUND")}
                >
                  <div className="floor-selector-label">Ground</div>
                  <div className="floor-selector-value">
                    {this.getFloorData("GROUND").sqft}
                  </div>
                </li>
                <li
                  className={`floor-selector-item ${this.setNav(
                    "LOWERGROUND"
                  )}`}
                  onClick={() => this.props.GoToState("LOWERGROUND")}
                >
                  <div className="floor-selector-label">Lower Ground</div>
                  <div className="floor-selector-value"></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Floors;
