import React, { Component } from "react";
import { G } from "../globals";
import U from "../utils";
import Instruction from "./instruction";

class Instructions extends Component {
  constructor() {
    super();

    this.state = {
      controlPriority: null,
    };

    document.addEventListener("camDone", (e) => {
      this.forceUpdate();

      this.setState({ controlPriority: G.cam.controls.controlPriority });
    });

    // this.controlPriority = G.cam.controls.controlPriority;
  }

  ShowInstructions() {
    let result = { orbit: false, pan: false, zoom: false };
    if (
      this.props.currentState !== undefined &&
      this.props.currentState &&
      this.props.currentState.camData !== undefined &&
      this.props.currentState.camData.controls !== undefined
    ) {
      this.props.currentState.camData.controls.map((c) => {
        if (c === "all") result = { orbit: true, pan: true, zoom: true };
        else if (c === "orbit" || c === "rotate") result.orbit = true;
        else if (c === "pan") result.pan = true;
        else if (c === "zoom") result.zoom = true;
        return null;
      });
    }
    return result;
  }

  GetInstruction(type) {
    const button =
      G.cam &&
      this.state.controlPriority &&
      this.state.controlPriority.PRIMARY === type
        ? "LEFT"
        : "RIGHT";
    switch (type) {
      case "orbit":
      case "rotate":
        return U.GetResponsiveMode().isTouch
          ? {
              icon: "touch-rotate",
              text: "Use two fingers to rotate camera",
            }
          : {
              icon: "touch-rotate",
              text: `${
                button === "RIGHT" ? "Right" : "Left"
              } click and drag to rotate camera`,
            };
      case "pan":
        return U.GetResponsiveMode().isTouch
          ? {
              icon: "touch-pan",
              text: "Touch and drag to look around",
            }
          : {
              icon: "touch-pan",
              text: `${
                button === "RIGHT" ? "Right" : "Left"
              } click and drag to pan around the area`,
            };
      case "zoom":
        return U.GetResponsiveMode().isTouch
          ? {
              icon: "touch-zoom",
              text: "Pinch to zoom in and out",
            }
          : {
              icon: "touch-zoom",
              text: "Scroll to zoom in and out",
            };
      default:
        break;
    }
  }

  render() {
    const s = this.ShowInstructions();
    return (
      <ul
        className={`instructions w-list-unstyled ${
          this.props.currentState &&
          this.props.currentState.options &&
          this.props.currentState.options.showInstructions
            ? ""
            : "hidden"
        }`}
      >
        <Instruction show={s.orbit} icon={this.GetInstruction("orbit").icon}>
          {this.GetInstruction("orbit").text}
        </Instruction>
        <li className={`instruction-divider ${s.orbit ? "" : "hidden"}`}>//</li>
        <Instruction show={s.pan} icon={this.GetInstruction("pan").icon}>
          {this.GetInstruction("pan").text}
        </Instruction>
        <li className={`instruction-divider ${s.pan ? "" : "hidden"}`}>//</li>
        <Instruction show={s.zoom} icon={this.GetInstruction("zoom").icon}>
          {this.GetInstruction("zoom").text}
        </Instruction>
        <li className={`instruction-divider ${s.zoom ? "" : "hidden"}`}>//</li>
        <li
          onClick={() => G.cam.Reset()}
          className="instruction-item reset clickable"
        >
          Reset
        </li>
      </ul>
    );
  }
}

export default Instructions;
