import React, { Component } from "react";
import { G } from "../globals";

class Overview extends Component {
  render() {
    return (
      <div className="slide">
        <div className="slide-content">
          <div className="slide-render-reserve"></div>
          <div className="slide-panel clickable">
            <div className="overview-slide-top">
              <div
                className="overview-img-container"
                onClick={() =>
                  G.OpenOverlay("showGallery", [
                    {
                      img: "./images/gallery/comp/overview-1.jpg",
                      caption: "",
                    },
                    {
                      img: "./images/gallery/comp/overview-2.jpg",
                      caption: "Entrance",
                    },
                    {
                      img: "./images/gallery/comp/overview-3.jpg",
                      caption: "Entrance",
                    },
                    {
                      img: "./images/gallery/comp/ground-1.jpg",
                      caption: "Ground Floor",
                    },
                    {
                      img: "./images/gallery/comp/ground-2.jpg",
                      caption: "Ground Floor",
                    },
                    {
                      img: "./images/gallery/comp/ground-3.jpg",
                      caption: "Ground Floor",
                    },
                    {
                      img: "./images/gallery/comp/ground-4.jpg",
                      caption: "Ground Floor",
                    },
                    {
                      img: "./images/gallery/comp/ground-5.jpg",
                      caption: "Ground Floor",
                    },
                    {
                      img: "./images/gallery/comp/terrace-1.jpg",
                      caption: "Communal 5th floor terrace",
                    },
                    {
                      img: "./images/gallery/comp/terrace-2.jpg",
                      caption: "Communal 5th floor terrace",
                    },
                    {
                      img: "./images/gallery/comp/terrace-3.jpg",
                      caption: "Communal 5th floor terrace",
                    },
                    {
                      img: "./images/gallery/comp/terrace-4.jpg",
                      caption: "Communal 5th floor terrace",
                    },
                    {
                      img: "./images/gallery/comp/terrace-5.jpg",
                      caption: "Communal 5th floor terrace",
                    },
                    {
                      img: "./images/gallery/comp/typical-1.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/typical-2.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/typical-3.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/typical-4.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/typical-5.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/typical-6.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/typical-7.jpg",
                      caption: "Typical Floor",
                    },
                    {
                      img: "./images/gallery/comp/wc-1.jpg",
                      caption: "WC",
                    },
                    {
                      img: "./images/gallery/comp/lower-ground-1.jpg",
                      caption: "Lower Ground - Bike Storage",
                    },
                    {
                      img: "./images/gallery/comp/lower-ground-2.jpg",
                      caption: "Lower Ground",
                    },
                    {
                      img: "./images/gallery/comp/lower-ground-3.jpg",
                      caption: "Lower Ground - Changing Rooms",
                    },
                  ])
                }
              >
                <div className="img-expand-button"></div>
              </div>
            </div>
            <div className="overview-slide-content">
              <h1 className="slide-header">
                WELCOME TO
                <br />
                <span className="fold-outline">THE FOLD</span>
              </h1>
              <div className="slide-copy">
                A responsive office environment in <br />a converted Victorian
                warehouse
              </div>

              <div className="overview-bee"></div>
              <div className="overview-butterfly"></div>
            </div>
          </div>
          <div className="slide-sub-panel clickable">
            <ul className="slide-sub-buttons w-list-unstyled">
              <li className="slide-sub-button">
                <div className="sustainability-tree"></div>
                <div
                  className="slide-sub-button-label"
                  onClick={() => G.OpenOverlay("showSust", true)}
                >
                  Sustainability
                </div>
              </li>
              <li className="slide-sub-button wellness-button">
                <div className="wellness-flower"></div>
                <div
                  className="slide-sub-button-label"
                  onClick={() => G.OpenOverlay("showWellness", true)}
                >
                  Wellness
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
