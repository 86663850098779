export const CONFIG = {
	r_shadow: true,
	r_anisotropy: 16,
	//DEBUG
	d_enableDebug: true,
	d_enableDebugUI: true,
	d_enableDebugVar: true,
	d_enableUI: true,
};

export function SetConfig(key, value) {
	console.log(`Setting ${key} to ${value}`);
	CONFIG[key] = value;
	FireEvent({ settingChanged: key, value: value });
}

function FireEvent(detail) {
	const eventDelegate = new CustomEvent("configChange", { bubbles: true, detail: detail });
	document.dispatchEvent(eventDelegate);
}
