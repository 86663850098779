import React, { Component } from "react";
import { G } from "../globals";

class OverlaySpecification extends Component {

  state = {
    show: false,
  }

  toggle = () => this.setState((currentState) => ({show: !currentState.show}));

  render() {
    return (
      <div className="overlay clickable">
        <div className="overlay-bg"></div>
        <div className="overlay-content">
          <div className="overlay-col-left">
            <div
              className="return-button"
              onClick={() => G.CloseOverlay("showSpec")}
            >
              Return
            </div>
            <div className="overlay-sub-title">Specification</div>
            <div className="spec-hero"></div>
            <div className="overlay-copy">
              A restoration and sustainable enhancement of a former warehouse
              providing 90,000 sq ft of stunning office space
            </div>
          </div>
          <div className="overlay-col-right">
            <ul className="overlay-featurespec-list w-list-unstyled">
              <li className="overlay-featurespec-item">
                <div className="spec-img breeam-spec"></div>
                <div className="spec-label">BREEAM ‘Excellent’</div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img occupancy-spec"></div>
                <div className="spec-label">Occupational density 1:8</div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img vrf-spec"></div>
                <div className="spec-label">
                  VRF Displacement 
                  <br />
                  Air Conditioning
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img air-spec"></div>
                <div className="spec-label">
                  12L Fresh air per 
                  <br />
                  second per person
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img led-spec"></div>
                <div className="spec-label">
                  LED Suspended 
                  <br />
                  strip lighting
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img roof-spec"></div>
                <div className="spec-label">
                  Newly constructed <br />
                  5th floor with private <br />
                  and communal roof <br />
                  terraces
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img ceiling-spec"></div>
                <div className="spec-label">
                  2.8m minimum floor 
                  <br />
                  to ceiling height
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img cycle-spec"></div>
                <div className="spec-label">131 Cycle Spaces</div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img park-spec"></div>
                <div className="spec-label">
                  19 Car parking spaces 
                  <br />
                  including 6 EV charging points
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img showers-spec"></div>
                <div className="spec-label">
                  End of trip facility block
                  <br /> with showers, lockers and changing areas
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img wired-spec"></div>
                <div className="spec-label">
                  Wiredscore
                  <br />
                  Gold Rating
                </div>
              </li>
              <li className="overlay-featurespec-item">
                <div className="spec-img epc-spec"></div>
                <div className="spec-label">EPC ‘B’</div>
              </li>
            </ul>
            
            <div
            className="more-details-btn"
            onClick={this.toggle}
          >{this.state.show ? 'Hide' : 'Show'} Detailed Specification
          </div>

            {this.state.show && <div>
              
              
            <div className="spec-cols">
              <ul className="spec-list w-list-unstyled">
                <li className="spec-item">
                  <span className="spec-title">
                    Office Floor Occupancy Design Criteria
                    <br />‍
                  </span>
                  Means of escape
                  <br />1 per 8m<span className="super">2</span> NIA
                  <br />‍<br />
                  Environmental comfort
                  <br />1 per 8m<span className="super">2</span> NIA
                  <br />‍<br />
                  WC provision
                  <br />1 per 8m<span className="super">2</span> NIA
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    FFL to plasterboard ceiling
                    <br />‍
                  </span>
                  Ground floor – 3450mm
                  <br />
                  1st floor – 3065mm
                  <br />
                  2nd floor – 2805mm
                  <br />
                  3rd floor – 2790mm
                  <br />
                  4th floor –  2800mm
                  <br />
                  5th (pavilion) floor – 3000mm
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    End of trip facilities
                    <br />‍
                  </span>
                  Cycle facilities are located within the estateunderground car
                  park immediately adjacentto the building.
                  <br />‍<br />
                  <strong>The facilities include:</strong>
                  <br />+ 204 lockers
                  <br />+ 131 cycle spaces including:
                  <br />
                  <span className="indent">
                    • 23 existing Sheffield stands
                    <br />• 6 new cycle stands at GF to accommodate non-standard
                    cycles
                    <br />• 10 vertical cycle racks
                    <br />• 93 tier style cycle racks
                    <br />• 8 charging points for electric cycles /scooters
                    <br />• 1 cycle maintenance station
                    <br />‍
                  </span>
                  <br />+ 11 showers
                  <br />+ 2x WCs
                  <br />+ 19 car parking spaces including 6 EV charging points
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Toilet accommodation
                    <br />‍
                  </span>
                  New WC facilities are included on every floor, including the
                  addition of 1 new accessible WC at every level at the south
                  end of the building.
                  <br />
                  <br />
                  Additional capped services are provided at the north end of
                  the building for installation of an additional accessible WC
                  in the event of a demise split of the floorplate.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Office area internal finishes:
                    <br />
                  </span>
                  <strong>Raised Floor</strong>
                  <br />
                  New Kingspan raised access floor system throughout.
                  <br />
                  <br />
                  <strong>Internal walls</strong>
                  <br />
                  Retained historic masonry walls in a combination of exposed
                  and painted brickwork.
                  <br />
                  Plasterboard with emulsion painted finish and painted timber
                  skirtings.
                  <br />
                  <br />
                  <strong>Ceilings</strong>
                  <br />
                  Existing building – Retained plasterboard ceilings to be
                  redecorated with emulsion paint.
                  <br />
                  <br />
                  Fifth floor pavilion – New plasterboard ceiling with zones of
                  perforated metal accessible ceiling panels with integrated
                  lighting and services.
                  <br />
                  <br />
                  <strong>Reception</strong>
                  <br />
                  Frameless glass shopfront glazing with frameless glass
                  motorised revolving door.
                  <br />
                  <br />
                  Concrete effect seamless resin floor finish.
                  <br />
                  <br />
                  Bespoke joinery wall to rear of reception with integrated
                  reeded glass crittal windows and low level storage.
                  <br />
                  <br />
                  Retained cast iron historic doors to the core. <br />
                  <br />
                  Cove style ceiling with feature up-lighting to accentuate the
                  generous floor to ceiling height.
                  <br />
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    External Terraces
                    <br />
                  </span>
                  Two external terraces are provided on the 5th floor.
                  <br />
                  <br />
                  Bespoke planters with integrated timber seating areas in PPC
                  metal finish.
                  <br />
                  <br />
                  Timber bar wrapping around external escape stair with drainage
                  and water supply.
                  <br />
                  <br />
                  Pergola overhead to provide shading.
                  <br />
                  <br />
                  Central planter with mature tree and integrated bespoke timber
                  seating.
                  <br />
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Lifts
                    <br />
                  </span>
                  Three passenger lifts with a capacity of 8 persons/750 kg
                  serving floors 1-4. The two lifts in the centre core extend to
                  the 5th floor pavilion and one lift has the required
                  facilities to provide firemans access. Performance is to BCO
                  standards with a stair factor of less than 25%.
                  <br />
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Staircases
                    <br />
                  </span>
                  The building has three staircases at each end and in the
                  centre of the floor plates providing means of escape from all
                  floors.
                  <br />
                </li>
              </ul>
              <ul className="spec-list w-list-unstyled">
                <li className="spec-item">
                  <span className="spec-title">
                    Security
                    <br />
                  </span>
                  The building is located within the Kensington Village estate
                  which has 24/7 security.
                  <br />
                  <br />
                  The recommendations of the Security Needs Assessment have been
                  carried through into the refurbishment, including access
                  control, CCTV and physical security.
                  <br />
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Office Occupier Plant Space
                    <br />‍
                  </span>
                  Plant space is available for tenants on the 4th floor north
                  roof.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Fire Protection Services
                    <br />‍
                  </span>
                  The building is fully sprinkler protected with the system
                  extended across all office floors. An L3 fire detection and
                  alarm system is also provided.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Building Structure and Load Capacity
                    <br />‍
                  </span>
                  The structure of the original building has been upgraded and
                  modified to accommodate the alterations and retains the
                  original load bearing masonry facades. A new transfer steel
                  deck has been constructed to support the 5th floor extension.
                  <br />
                  <br />
                  All load bearing elements of the structure are protected to
                  provide 60 minutes fire resistance. Floors and riser shafts
                  also have 60 minutes fire resisting construction. <br />
                  <br />
                  <strong>Load capacities are:</strong>
                  <br />
                  Cat A offices floors
                  <br />
                  1-5 – 2.5 kN/m<span className="super">2</span> plus 1.0kN/m
                  <span className="super">2</span> for partitions
                  <br />‍<br />
                  Cat A offices ground floor
                  <br />
                  3.0 kN/m<span className="super">2</span> plus 1.0kN/m
                  <span className="super">2</span> for partitions
                  <br />
                  <br />
                  Main roof plant area <br />
                  7.5 kN/m<span className="super">2</span> <br />
                  <br />
                  Other roof plant areas
                  <br />
                  2.5 kN/m<span className="super">2</span> <br />
                  <br />
                  Reception
                  <br />
                  12 l/s/person at 1 person per 8 m
                  <span className="super">2</span> at 70% occupancy, extract at
                  95% of supply
                  <br />
                  <br />
                  WC’s
                  <br />
                  10 air changes/hour extract, dedicated fresh air supply
                  <br />
                  <br />
                  Small power
                  <br />
                  25 W/m<span className="super">2</span> <br />‍<br />
                  Lighting
                  <br />8 W/m<span className="super">2</span> 
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Heating, Cooling and Ventilation
                    <br />‍
                  </span>
                  Underfloor Heating and cooling will be provided to the
                  building by multiple HVRF (hybrid variable refrigerant flow)
                  systems on floors 1-4. The system is based on a 2 Pipe Heat
                  Recovery VRF System but uses water as a heat exchange medium
                  between the Hybrid Branch Controller and the indoor units.
                  <br />
                  <br />
                  Fresh air is provided to all office floors by air handling
                  unit distributing air to the back of the fan coils through
                  ductwork in the risers and raised floor void.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Services Design Criteria
                    <br />
                    <strong>‍</strong>
                  </span>
                  <strong>
                    Internal design temperatures:
                    <br />
                  </strong>
                  Cat A offices and reception – 21 °C winter minimum, <br />
                  24 °C summer maximum +/- 2 °C
                  <br />
                  Toilets and circulation – 19 °C winter minimum <br />
                  <br />
                  <strong>
                    Ventilation rates:
                    <br />
                  </strong>
                  Cat A offices – 12 l/s/person at 1 person per 8 m
                  <span className="super">2</span> plus 10%, extract at 95% of
                  supply.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Services Design Criteria
                    <br />
                  </span>
                  Utility metered power to the building is provided at low
                  voltage from a DNO substation located in the estate
                  underground car park immediately adjacent to the building.
                  <br />
                  <br />
                  The capacity to the building is 850 kVA and it is understood
                  that up to 1.2 MVA is available at the substation.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Lighting
                    <br />‍
                  </span>
                  Lighting has been designed to CIBSE standards, in the offices
                  500 lux at the working plane with a uniformity of 0.6 and a
                  unified glare rating of 19.
                </li>
                <li className="spec-item">
                  <span className="spec-title">
                    Communications and Wifi
                    <br />‍
                  </span>
                  The Kensington Village estate has existing connections from
                  multiple service providers with three diverse entry points
                  from the public highway. There will be three ducts entering
                  the building from the estate distribution.
                  <br />
                  <br />
                  The building has a landlord telco room with installed fibre
                  cables from there to each potential tenant demise, and riser
                  space to allow tenants to instal their own service provider
                  connections if preferred.
                  <br />
                  <br />
                  Wireless access points and a 5G booster system will be
                  provided to the reception, cycle facilities area and terrace.
                </li>
              </ul>
            </div>

              
              
              
              </div>}

          </div>
          <div
            className="overlay-close"
            onClick={() => G.CloseOverlay("showSpec")}
          ></div>
        </div>
      </div>
    );
  }
}

export default OverlaySpecification;
