import React, { Component } from "react";

import * as THREE from "three";
import Camera from "./Camera";
import { G } from "../globals";
import { CONFIG } from "../config";
import Loop from "./Loop";
import Lights from "./Lights";
import Interact from "./Interact";
import Ground from "./Ground";
import MarkerController from "./markerController";
import U from "../utils";

class Viz extends Component {
  constructor() {
    super();
    this.ResetRender = this.ResetRender.bind(this);
  }

  componentDidMount() {
    G.scene = new THREE.Scene();
    G.markerScene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    G.renderer = this.renderer;
    this.renderer.setClearColor(new THREE.Color("#C0C4DD"), 1);
    this.renderer.shadowMap.enabled = false;
    this.renderer.setPixelRatio(
      U.GetResponsiveMode().highDefMode >= 1 && window.devicePixelRatio
        ? window.devicePixelRatio
        : 1
    );
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.mainDiv.appendChild(this.renderer.domElement);

    // G.scene.fog = new THREE.FogExp2("#EBEBEB", 0.005);

    //quick dummy if debug is disabled
    // if (!CONFIG.d_enableDebug) {
    // 	G.debug.TrackVar = () => {};
    // 	G.debug.TrackEntity = () => {};
    // }

    G.forceDisableInteraction = false;

    G.cam = new Camera();

    G.mouse = new THREE.Vector2();

    new Lights();
    new Interact();
    new Ground();
    new MarkerController();

    //TWEAK RENDERER
    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 0.5;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.sortObjects = true;
    this.renderer.autoClear = false;

    const mainLoop = new Loop(() => {
      this.renderer.clear();
      this.renderer.render(G.scene, G.cam.camera);
      this.renderer.clearDepth();
      this.renderer.render(G.markerScene, G.cam.camera);
    });
    mainLoop.start();

    //add renderer responsiveness
    window.addEventListener("resize", (e) => {
      this.ResetRender();
    });

    window.addEventListener("focus", () => {
      // console.log('FOCUS');
      this.ResetRender();
    });

    window.addEventListener("orientationchange", () => {
      this.ResetRender();
    });

    window.addEventListener("pageshow", () => {
      // console.log('PAGESHOW');
      this.ResetRender();
    });

    document.addEventListener("visibilitychange", () => {
      // console.log('VISIB CHANGE');
      this.ResetRender();
    });
  }

  ResetRender() {
    if (!G.cam || !this.renderer || !G.currentState) return;
    G.cam.camera.aspect = window.innerWidth / window.innerHeight;
    G.cam.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    G.cam.camera.setFocalLength(
      G.currentState &&
        G.currentState.camData &&
        G.currentState.camData.camera.fov
        ? G.currentState.camData.camera.fov
        : G.cam.camera.fov
    );
    // this.renderer.setPixelRatio(1);
  }

  render() {
    return <div ref={(ref) => (this.mainDiv = ref)} className="render" />;
  }
}

export default Viz;
