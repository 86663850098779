import * as THREE from "three";
import Entity from "../Entity";
import { CONFIG } from "../../config";
import { G } from "../../globals";
import U from "../../utils";

class FloorMarker extends Entity {
	constructor(params) {
		super();

		this.isVisible = false;

		this.marker = new THREE.Group();

		this.params = params;

		this.markerImage = new THREE.Mesh(
			new THREE.PlaneGeometry(params.iconSize.width, params.iconSize.height),
			new THREE.MeshBasicMaterial({
				color: new THREE.Color("#ffffff"),
				map: params.icon,
				transparent: true,
				toneMapped: false,
			})
		);

		this.markerImage.material.map.encoding = THREE.sRGBEncoding;
		this.markerImage.material.map.anisotropy = CONFIG.r_anisotropy;
		this.markerImage.material.map.magFilter = THREE.LinearFilter;
		this.markerImage.material.map.minFilter = THREE.LinearMipMapLinearFilter;

		this.markerImage.material.blending = THREE.CustomBlending;
		this.markerImage.material.blendSrc = THREE.OneFactor;
		this.markerImage.material.blendDst = THREE.OneMinusSrcAlphaFactor;

		this.marker.add(this.markerImage);

		this.enableInteraction(this.markerImage);

		setTimeout(() => {
			this.markerImage.rotation.set(-Math.PI / 2, 0, U.convertDegreesToRadians(285));
			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.location.y,
				params.markerData.location.z
			);
		}, 200);
	}

	onMouseEnter() {
		// console.log(this);
		if (this.isVisible) {
			this.markerImage.material.map = this.params.iconActive;
			document.body.style.cursor = "pointer";
		}
	}

	onMouseLeave() {
		if (this.isVisible) {
			this.markerImage.material.map = this.params.icon;
			document.body.style.cursor = "auto";
		}
	}

	onClick() {
		if (this.isVisible && this.params.markerData.scene) {
			G.OpenOverlay("showVirtual", this.params.markerData.scene);
		} else if (this.isVisible && this.params.markerData.images) {
			G.OpenOverlay("showGallery", this.params.markerData.images);
		}
	}
}

export default FloorMarker;
