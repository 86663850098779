import React, { Component } from "react";
import { G } from "../globals";

class Building extends Component {
  render() {
    return (
      <div className="slide">
        <div className="slide-content">
          <div className="slide-render-reserve"></div>
          <div className="slide-panel clickable">
            <div className="overview-slide-top full-width">
              <div className="buiilding-hero"></div>
              <div className="building-gfk1"></div>
            </div>
            <div className="overview-slide-content">
              <h1 className="slide-header">
                INSIDE
                <br />
                <span className="fold-outline">THE FOLD</span>
              </h1>
              <div className="slide-copy">
                A restored Victorian Warehouse designed to facilitate coming
                together, exploring ideas and creating solutions.
              </div>
              <ul className="slide-nav w-list-unstyled">
                <li
                  className="slide-nav-item"
                  onClick={() =>
                    G.OpenOverlay("showGallery", [
                      {
                        img: "./images/gallery/comp/overview-1.jpg",
                        caption: "",
                      },
                      {
                        img: "./images/gallery/comp/overview-2.jpg",
                        caption: "Entrance",
                      },
                      {
                        img: "./images/gallery/comp/overview-3.jpg",
                        caption: "Entrance",
                      },
                      {
                        img: "./images/gallery/comp/ground-1.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-2.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-3.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-4.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/ground-5.jpg",
                        caption: "Ground Floor",
                      },
                      {
                        img: "./images/gallery/comp/terrace-1.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-2.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-3.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-4.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/terrace-5.jpg",
                        caption: "Communal 5th floor terrace",
                      },
                      {
                        img: "./images/gallery/comp/typical-1.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-2.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-3.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-4.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-5.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-6.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/typical-7.jpg",
                        caption: "Typical Floor",
                      },
                      {
                        img: "./images/gallery/comp/wc-1.jpg",
                        caption: "WC",
                      },
                      {
                        img: "./images/gallery/comp/lower-ground-1.jpg",
                        caption: "Lower Ground - Bike Storage",
                      },
                      {
                        img: "./images/gallery/comp/lower-ground-2.jpg",
                        caption: "Lower Ground",
                      },
                      {
                        img: "./images/gallery/comp/lower-ground-3.jpg",
                        caption: "Lower Ground - Changing Rooms",
                      },
                    ])
                  }
                >
                  Gallery
                </li>
                <li className="slide-div-item"></li>
                <li
                  className="slide-nav-item"
                  onClick={() => G.OpenOverlay("showSpec", true)}
                >
                  Specification
                </li>
                <li className="slide-div-item"></li>
                <li
                  className="slide-nav-item"
                  onClick={() => this.props.GoToState("FIFTH")}
                >
                  Floors
                </li>
              </ul>
              <div className="building-gfk2"></div>
            </div>
          </div>
          <div className="slide-sub-panel"></div>
        </div>
      </div>
    );
  }
}

export default Building;
