import * as THREE from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { G } from "../../globals";
import { CONFIG } from "../../config";
import U from "../../utils";
import Entity from "../Entity";
import Loop from "../Loop";

const allAmenityLabels = [];

class AmenityLabel extends Entity {
	constructor(params) {
		super();

		this.ActivateMarker = this.ActivateMarker.bind(this);

		// this.showMode = params.showMode === 'scaleWithZoom' ? this.ScaleWithZoom : params.showMode === 'proximityReveal' ? this.ProximityReveal : this.ProximityReveal;
		this.proximityDist = params.proximityDist ? params.proximityDist : { max: 300, min: 200 };
		this.params = params;

		this.isActive = false;

		// this.scaleRate = 400;
		this.scaleRate = {
			//make markers larger above a certain zoom level
			grow: {
				//active above this level
				minZoom:
					params.scaleRate && params.scaleRate.grow && params.scaleRate.grow.minZoom
						? params.scaleRate.grow.minZoom
						: 400,
				//reach max size by this zoom level
				maxZoom:
					params.scaleRate && params.scaleRate.grow && params.scaleRate.grow.maxZoom
						? params.scaleRate.grow.maxZoom
						: 1000,
				//target scale at max zoom - in other words start at 1x scale at min zoom, and go to 4x scale at max zoom
				rate:
					params.scaleRate && params.scaleRate.grow && params.scaleRate.grow.rate
						? params.scaleRate.grow.rate
						: 3,
			},
			//make markers smaller below a certain zoom level
			shrink: {
				//shrink below this level
				maxZoom:
					params.scaleRate && params.scaleRate.shrink && params.scaleRate.shrink.maxZoom
						? params.scaleRate.shrink.maxZoom
						: 230,
				//get to min size by this point
				minZoom:
					params.scaleRate && params.scaleRate.shrink && params.scaleRate.shrink.minZoom
						? params.scaleRate.shrink.minZoom
						: 50,
				//target min scale
				rate:
					params.scaleRate && params.scaleRate.shrink && params.scaleRate.shrink.rate
						? params.scaleRate.shrink.rate
						: 0.2,
			},
		};
		this.overrideApplied = false;

		const meshes = [];
		const geos = [];
		const fontSize = params.markerData.fontSize ? params.markerData.fontSize : 5;
		const lineHeight = 3;
		this.marker = new THREE.Group();
		this.markerVLookGroup = new THREE.Group();
		this.markerLookGroup = new THREE.Group();
		this.markerLabelGroup = new THREE.Group();
		this.markerTextGroup = new THREE.Group();
		this.markerStaticGroup = new THREE.Group();
		this.ScaleWithZoom = this.ScaleWithZoom.bind(this);
		// this.ProximityReveal = this.ProximityReveal.bind(this);

		const mat = new THREE.MeshBasicMaterial({
			color: new THREE.Color(params.markerData.color ? params.markerData.color : "white"),
			roughness: 0,
			toneMapped: false,
		});

		if (params.markerData.text && Array.isArray(params.markerData.text)) {
			params.markerData.text.map((t) => {
				geos.push(
					new THREE.TextGeometry(t, {
						font: params.font,
						size: fontSize,
						height: 0.01,
						curveSegments: 12,
					})
				);
			});

			geos.map((g, i) => {
				const m = new THREE.Mesh(g, mat);
				const gap = i > 0 ? -fontSize * i - lineHeight : -fontSize * i;
				m.position.set(0, gap, 0);
				meshes.push(m);
			});
		} else if (params.markerData.text) {
			const g = new TextGeometry(params.markerData.text, {
				font: params.font,
				size: fontSize,
				height: 0.01,
				curveSegments: 12,
			});

			const m = new THREE.Mesh(g, mat);

			meshes.push(m);
		}

		meshes.map((mesh) => {
			mesh.geometry.computeBoundingBox();
			mesh.position.set(
				-(mesh.geometry.boundingBox.max.x - mesh.geometry.boundingBox.min.x) / 2,
				0,
				0
			);
			this.markerTextGroup.add(mesh);

			if (params.markerData.outline) {
				const planeMesh = new THREE.Mesh(
					new THREE.PlaneGeometry(1, 1),
					new THREE.MeshBasicMaterial({
						color: new THREE.Color(params.markerData.outline),
						depthTest: true,
						toneMapped: false,
					})
				);

				planeMesh.renderOrder = 3;

				let centerLabelPos;

				centerLabelPos = {
					x: 0,
					z: (mesh.geometry.boundingBox.max.z - mesh.geometry.boundingBox.min.z) / 2,
				};

				const planePadding = { x: 6, y: 6 };

				planeMesh.scale.set(
					mesh.geometry.boundingBox.max.x + planePadding.x,
					mesh.geometry.boundingBox.max.y + planePadding.y,
					mesh.geometry.boundingBox.max.z
				);
				planeMesh.position.set(
					centerLabelPos.x,
					mesh.geometry.boundingBox.max.y / 2,
					centerLabelPos.z + -0.1
				);

				this.markerTextGroup.add(planeMesh);
			}
		});

		if (this.params.icon) {
			this.icon = new THREE.Mesh(
				new THREE.PlaneGeometry(params.iconSize.width, params.iconSize.height),
				new THREE.MeshBasicMaterial({
					color: new THREE.Color("#ffffff"),
					map: params.icon,
					transparent: true,
					toneMapped: false,
					// depthTest: false,
				})
			);

			this.icon.material.map.encoding = THREE.sRGBEncoding;
			// this.icon.material.map.anisotropy = CONFIG.r_anisotropy;
			// this.icon.material.map.magFilter = THREE.LinearFilter;
			// this.icon.material.map.minFilter = THREE.LinearMipMapLinearFilter;

			this.icon.renderOrder = 4;

			this.markerLabelGroup.add(this.icon);

			const singleLineOffset = Array.isArray(params.markerData.text)
				? 0
				: this.params.iconSize.height / 2;
			this.markerTextGroup.position.set(
				this.params.iconSize.width / 2 + 10,
				-singleLineOffset / 2.5,
				0
			); //width + a bit of margin
		}

		const pinPointHeight = this.params.markerData.pinPointHeight
			? this.params.markerData.pinPointHeight
			: 15;

		const pinPoint = new THREE.Mesh(
			new THREE.CylinderGeometry(0.1, 0.1, pinPointHeight, 3, 1),
			new THREE.MeshBasicMaterial({
				color: params.markerData.pinpointColor
					? new THREE.Color(params.markerData.pinpointColor)
					: new THREE.Color("#ffffff"),
				toneMapped: false,
				// depthWrite: false,
				// depthTest: false,
			})
		);

		this.markerTextGroup.position.set(0, 12, 0);
		this.markerTextGroup.visible = false;

		pinPoint.position.set(0, pinPointHeight / 2, 0);

		this.markerStaticGroup.add(pinPoint);

		this.markerLabelGroup.add(this.markerTextGroup);
		this.markerVLookGroup.add(this.markerLabelGroup);
		this.markerVLookGroup.add(this.markerStaticGroup);
		this.markerLookGroup.add(this.markerVLookGroup);
		this.marker.add(this.markerLookGroup);

		allAmenityLabels.push(this);

		this.loop = new Loop(() => {
			if (this.marker.visible) {
				this.ScaleWithZoom();
				this.RotateToFaceCamera();
			}
		}).start();

		const zOffset = this.params.markerData.zOffset ? this.params.markerData.zOffset : 4;

		const forceScale = this.params.markerData.forceScale
			? this.params.markerData.forceScale
			: params.forceScale;

		//letting things load
		setTimeout(() => {
			this.markerLabelGroup.position.set(
				0,
				pinPointHeight + zOffset, // +3
				0
			);

			this.marker.position.set(
				params.markerData.location.x,
				params.markerData.height * forceScale,
				params.markerData.location.z
			);
			this.marker.scale.set(
				this.marker.scale.x * forceScale,
				this.marker.scale.y * forceScale,
				this.marker.scale.z * forceScale
			);
			// this.marker.rotation.set(
			// 	-Math.PI / 2,
			// 	0,
			// 	U.convertDegreesToRadians(params.markerData.rotation)
			// );
			// this.markerMeshLineOne.position.set(-centerPos.x, this.markerMeshLineOne.position.y, -centerPos.z);
		}, 300);
	}

	onStateChange(newState) {
		if (newState.markers && newState.markers.includes(this.params.name)) {
			if (newState.name === "TRANSPORT") {
				this.showMode = this.ScaleWithZoom;
				this.overrideApplied = true;
			} else if (this.overrideApplied) {
				this.showMode =
					this.params.showMode === "scaleWithZoom"
						? this.ScaleWithZoom
						: this.params.showMode === "proximityReveal"
						? this.ProximityReveal
						: this.ProximityReveal;
				this.overrideApplied = false;
			}
		}
	}

	ScaleWithZoom() {
		const d = this.marker.position.distanceTo({
			x: G.cam.camera.position.x,
			y: 0,
			z: G.cam.camera.position.z,
		});
		let scale = 1;
		if (d > this.proximityDist.min && G.cam.camera.zoomLevel < this.scaleRate.grow.minZoom) {
			scale = U.Clamp(
				U.MapRange(d, this.proximityDist.max, this.proximityDist.min, 0.1, 1),
				0.1,
				1
			);
		} else if (G.cam.camera.zoomLevel > this.scaleRate.grow.minZoom) {
			scale = U.MapRange(
				G.cam.camera.zoomLevel,
				this.scaleRate.grow.minZoom,
				this.scaleRate.grow.maxZoom,
				1,
				this.scaleRate.grow.rate
			);
			if (scale < 1) scale = 1;
		} else if (G.cam.camera.zoomLevel < this.scaleRate.shrink.maxZoom) {
			scale = U.MapRange(
				G.cam.camera.zoomLevel,
				this.scaleRate.shrink.minZoom,
				this.scaleRate.shrink.maxZoom,
				this.scaleRate.shrink.rate,
				1
			);
		}

		this.markerLookGroup.scale.set(scale, scale, scale);
	}

	static ActivateAmenityMarker(id) {
		const foundMarker = allAmenityLabels.find((a) => a.params.id === id);
		console.log(foundMarker);
		if (foundMarker) foundMarker.ActivateMarker();
	}

	static ResetAllMarkers() {
		allAmenityLabels.map((a) => a.ResetMarker());
	}

	ActivateMarker() {
		if (this.isActive) return;
		this.isActive = true;
		allAmenityLabels.map((a) => a.ResetMarker());
		// console.log(this.id);
		// console.log(this.params.markerData.iconActive);
		// console.log(this);
		this.icon.material.map = G.loadedAssets[this.params.markerData.iconActive];
		this.markerTextGroup.visible = true;
	}

	ResetMarker() {
		this.isActive = false;
		this.icon.material.map = G.loadedAssets[this.params.markerData.icon];
		this.markerTextGroup.visible = false;
	}

	// ProximityReveal() {
	// 				//set to 0 when more than 300, and interpolate to 1 when closer than 200
	// 				const d = this.marker.position.distanceTo(G.cam.camera.position);
	// 				const s = U.Clamp(U.MapRange(d, this.proximityDist.min, this.proximityDist.max, 0.1, 1), 0.1, 1) ;
	// 				this.markerLookGroup.scale.set(s, s, s);
	// }

	RotateToFaceCamera() {
		if (G.cam.camera.getPolarAngle && G.cam.camera.getPolarAngle() < 0.25) {
			this.markerLookGroup.rotation.set(-Math.PI / 2, 0, G.cam.camera.getAzimuthalAngle());
			this.markerVLookGroup.rotation.set(0, 0, 0);
		} else if (G.cam.camera.getPolarAngle) {
			this.markerLookGroup.rotation.set(0, G.cam.camera.getAzimuthalAngle(), 0);
			this.markerVLookGroup.rotation.set(
				-U.MapRange(G.cam.camera.getPolarAngle(), 0, Math.PI / 2, Math.PI / 2, 0),
				0,
				0
			); //polar angle = 1.5 (half pi) -  angle should be 0, polar angle = 0 - angle should be 1.5 (or half pi)
			// this.markerLookGroup.lookAt(G.cam.camera.position);
		}
	}
}

G.activateAmenityMarker = AmenityLabel.ActivateAmenityMarker;
G.resetAmenityMarkers = AmenityLabel.ResetAllMarkers;

export default AmenityLabel;
