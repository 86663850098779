import React, { Component } from "react";
import { G } from "../globals";

class Connections extends Component {
  constructor() {
    super();

    this.state = {
      currentTab: null,
    };
  }

  getScene(tab) {
    let scene = null;

    switch (tab) {
      case "kens":
        scene = {
          target: {
            pos: { x: -677.484, y: 6.9, z: -23.52 },
            duration: 2000,
          },
          camera: {
            pos: { x: -1753.501, y: 2266.935, z: 369.321 },
            duration: 2000,
            fov: 20,
            filmOffset: -6,
          },
        };
        break;
      case "earls":
        scene = {
          target: {
            pos: { x: 1611.669, y: 6.9, z: 160.752 },
            duration: 2000,
          },
          camera: {
            pos: { x: 3222.903, y: 1916.394, z: 582.242 },
            duration: 2000,
            fov: 20,
            filmOffset: -6,
          },
        };
        break;
      case "barons":
        scene = {
          target: {
            pos: { x: -2955.672, y: 6.9, z: 149.708 },
            duration: 2000,
          },
          camera: {
            pos: { x: -5471.53, y: 2530.401, z: 832.859 },
            duration: 2000,
            fov: 20,
            filmOffset: -6,
          },
        };
        break;
      case "olympia":
        scene = {
          target: {
            pos: { x: -1008.268, y: 6.9, z: -2066.963 },
            duration: 2000,
          },
          camera: {
            pos: { x: -2876.143, y: 2708.392, z: -3608.805 },
            duration: 2000,
            fov: 20,
            filmOffset: -6,
          },
        };
        break;
      case "hammer":
        scene = {
          target: {
            pos: { x: -5453.568, y: 6.9, z: -325.187 },
            duration: 2000,
          },
          camera: {
            pos: { x: -9116.596, y: 2918.976, z: -27.176 },
            duration: 2000,
            fov: 20,
            filmOffset: -6,
          },
        };
        break;
      default:
        break;
    }

    if (scene) {
      G.cam.DisableMapControls();
      G.cam.SetScene(scene);
    }
  }

  setTab(tab) {
    if (this.state.currentTab === tab) {
      G.cam.SetScene(G.currentState.camData);
      this.setState({ currentTab: null });
    } else {
      this.getScene(tab);
      this.setState({ currentTab: tab });
    }
  }

  render() {
    return (
      <div className="slide">
        <div className="slide-content connections-content ">
          <div className="slide-render-reserve connections-reserve"></div>
          <div className="slide-panel clickable">
            <div className="overview-slide-top full-width">
              <div className="connections-hero">
                <div className="connections-gfk1"></div>
                <div className="connections-lines"></div>
              </div>
            </div>
            <div className="overview-slide-content connections-content">
              <h1 className="slide-header">
                GETTING TO
                <br />
                <span className="fold-outline">THE FOLD</span>
              </h1>
              <div className="travel-times-title">Travel times</div>
              <ul className="travel-time-list w-list-unstyled">
                <li
                  className="travel-time-item"
                  onClick={() => this.setTab("kens")}
                >
                  <div
                    className={`${
                      this.state.currentTab === "kens"
                        ? "travel-time-contract"
                        : "travel-time-expand"
                    }`}
                  ></div>
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">
                        West Kensington{" "}
                        <span className="travel-icon-tube">     </span>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time bike-time">2 mins</div>
                      <div className="travel-time walk-time">6 mins</div>
                    </div>
                  </div>
                  <div
                    className={`travel-item-expandable-wrapper ${
                      this.state.currentTab === "kens" ? "" : "hidden"
                    }`}
                  >
                    <ul className="travel-cat-list w-list-unstyled">
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-district-line"></div>
                        <div className="travel-cat-label">District Line</div>
                      </li>
                    </ul>
                    <ul className="travel-subtime-list w-list-unstyled">
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Victoria</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">14 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Wimbledon</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">20 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Embankment</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">21 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Whitechapel</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">35 mins</div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  className="travel-time-item"
                  onClick={() => this.setTab("olympia")}
                >
                  <div
                    className={`${
                      this.state.currentTab === "olympia"
                        ? "travel-time-contract"
                        : "travel-time-expand"
                    }`}
                  ></div>
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">
                        Kensington Olympia{" "}
                        <span className="travel-icon-rail">     </span>
                        <span className="travel-icon-overground">       </span>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time bike-time">4 mins</div>
                      <div className="travel-time walk-time">10 mins</div>
                    </div>
                  </div>
                  <div
                    className={`travel-item-expandable-wrapper ${
                      this.state.currentTab === "olympia" ? "" : "hidden"
                    }`}
                  >
                    <ul className="travel-cat-list w-list-unstyled">
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-district-line"></div>
                        <div className="travel-cat-label">District Line</div>
                      </li>
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-overground-line"></div>
                        <div className="travel-cat-label">Overground</div>
                      </li>
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-rail-line"></div>
                        <div className="travel-cat-label">National Rail</div>
                      </li>
                    </ul>
                    <ul className="travel-subtime-list w-list-unstyled">
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Clapham Junction
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">10 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Paddington</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">25 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Hampstead Heath
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">35 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Liverpool Street
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">35 mins</div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  className="travel-time-item"
                  onClick={() => this.setTab("earls")}
                >
                  <div
                    className={`${
                      this.state.currentTab === "earls"
                        ? "travel-time-contract"
                        : "travel-time-expand"
                    }`}
                  ></div>
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">
                        Earl’s Court{" "}
                        <span className="travel-icon-tube">     </span>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time bike-time">6 mins</div>
                      <div className="travel-time walk-time">10 mins</div>
                    </div>
                  </div>
                  <div
                    className={`travel-item-expandable-wrapper ${
                      this.state.currentTab === "earls" ? "" : "hidden"
                    }`}
                  >
                    <ul className="travel-cat-list w-list-unstyled">
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-district-line"></div>
                        <div className="travel-cat-dot dot-circle-line"></div>
                        <div className="travel-cat-label">
                          District / Circle Line
                        </div>
                      </li>
                    </ul>
                    <ul className="travel-subtime-list w-list-unstyled">
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Paddington</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">10 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Victoria</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">12 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Wimbledon</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">18 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Blackfriars</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">24 mins</div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  className="travel-time-item"
                  onClick={() => this.setTab("barons")}
                >
                  <div
                    className={`${
                      this.state.currentTab === "barons"
                        ? "travel-time-contract"
                        : "travel-time-expand"
                    }`}
                  ></div>
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">
                        Barons Court{" "}
                        <span className="travel-icon-tube">     </span>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time bike-time">5 mins</div>
                      <div className="travel-time walk-time">12 mins</div>
                    </div>
                  </div>
                  <div
                    className={`travel-item-expandable-wrapper ${
                      this.state.currentTab === "barons" ? "" : "hidden"
                    }`}
                  >
                    <ul className="travel-cat-list w-list-unstyled">
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-piccadilly-line"></div>
                        <div className="travel-cat-label">Piccadilly Line</div>
                      </li>
                    </ul>
                    <ul className="travel-subtime-list w-list-unstyled">
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Green Park</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">14 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Leicester Square
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">17 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Finsbury Park
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">35 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Heathrow</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">38 mins</div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li
                  className="travel-time-item"
                  onClick={() => this.setTab("hammer")}
                >
                  <div
                    className={`${
                      this.state.currentTab === "hammer"
                        ? "travel-time-contract"
                        : "travel-time-expand"
                    }`}
                  ></div>
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">
                        Hammersmith{" "}
                        <span className="travel-icon-tube">     </span>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time bike-time">9 mins</div>
                      <div className="travel-time walk-time">21 mins</div>
                    </div>
                  </div>
                  <div
                    className={`travel-item-expandable-wrapper ${
                      this.state.currentTab === "hammer" ? "" : "hidden"
                    }`}
                  >
                    <ul className="travel-cat-list w-list-unstyled">
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-district-line"></div>
                        <div className="travel-cat-dot dot-circle-line"></div>
                        <div className="travel-cat-label">
                          District / Circle Line
                        </div>
                      </li>
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-hammersmith-line"></div>
                        <div className="travel-cat-label">
                          Hammersmith &amp; City
                        </div>
                      </li>
                      <li className="travel-cat-item">
                        <div className="travel-cat-dot dot-piccadilly-line"></div>
                        <div className="travel-cat-label">Piccadilly</div>
                      </li>
                    </ul>
                    <ul className="travel-subtime-list w-list-unstyled">
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Paddington Station
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">20 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">Victoria</div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">20 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Piccadilly Circus
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">29 mins</div>
                      </li>
                      <li className="travel-subtime-item">
                        <div className="travel-subtime-label">
                          Liverpool Street
                        </div>
                        <div className="travel-subtime-divider"></div>
                        <div className="travel-time">36 mins</div>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="travel-time-item">
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">
                        Heathrow <span className="travel-icon-plane">    </span>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time car-time">24 mins</div>
                    </div>
                  </div>
                </li>
                <li className="travel-time-item">
                  <div className="travel-time-wrapper">
                    <div className="travel-item-left">
                      <div className="travel-item-title">London</div>
                      <div className="travel-item-label">
                        <em>The Strand</em>
                      </div>
                    </div>
                    <div className="travel-item-right">
                      <div className="travel-time car-time">25 mins</div>
                    </div>
                  </div>
                </li>
              </ul>

              <div className="travel-times-title">
                <span className="bike-icon">      </span> Bike Times
              </div>

              <ul className="travel-time-list w-list-unstyled">
                <li className="travel-time-item travel-item-inline">
                  <div className="travel-item-left">
                    <div className="travel-item-title">
                      Clapham Junction 
                      <span className="travel-icon-rail">     </span>
                      <span className="travel-icon-overground">      </span>
                    </div>
                  </div>
                  <div className="travel-item-right">
                    <div className="travel-time bike-time">20 mins</div>
                  </div>
                </li>
                <li className="travel-time-item travel-item-inline">
                  <div className="travel-item-left">
                    <div className="travel-item-title">
                      Paddington Station 
                      <span className="travel-icon-rail">     </span>{" "}
                      <span className="travel-icon-crossrail">     </span>
                      <span className="travel-icon-tube">       </span>
                    </div>
                  </div>
                  <div className="travel-item-right">
                    <div className="travel-time bike-time">20 mins</div>
                  </div>
                </li>
                <li className="travel-time-item travel-item-inline">
                  <div className="travel-item-left">
                    <div className="travel-item-title">
                      Victoria Station{" "}
                      <span className="travel-icon-rail">     </span>
                      <span className="travel-icon-tube">       </span>
                    </div>
                  </div>
                  <div className="travel-item-right">
                    <div className="travel-time bike-time">20 mins</div>
                  </div>
                </li>

                <li className="travel-time-item travel-item-inline">
                  <div className="travel-item-left">
                    <div className="travel-item-title">
                      Marylebone Station 
                      <span className="travel-icon-rail">     </span>
                      <span className="travel-icon-tube">      </span>
                    </div>
                  </div>
                  <div className="travel-item-right">
                    <div className="travel-time bike-time">26 mins</div>
                  </div>
                </li>

                <li className="travel-time-item travel-item-inline">
                  <div className="travel-item-left">
                    <div className="travel-item-title">
                      Euston Station{" "}
                      <span className="travel-icon-rail">     </span>
                      <span className="travel-icon-overground">       </span>
                    </div>
                  </div>
                  <div className="travel-item-right">
                    <div className="travel-time bike-time">35 mins</div>
                  </div>
                </li>
              </ul>

              <div className="connections-gfk2"></div>
              <div className="connections-tube"></div>
            </div>
          </div>
          <div className="slide-sub-panel"></div>
        </div>
      </div>
    );
  }
}

export default Connections;
